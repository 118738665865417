import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { observer } from "mobx-react-lite";

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    maxWidth: '375px',
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    '& span': {
      fontSize: '0.9rem',
    },
    '& span span': {
      fontSize: '0.75rem',
    }

  },
  author: {
    display: 'block',
    textAlign: 'right',
  },
  listItemText: {
    whiteSpace: 'pre-wrap',
  },
}));

const NoteList = observer((props) => {
  const classes = useStyles();

  return (
    <List id="dic-modal-list" className={classes.list}>
    {props.notes.map((note) =>
      note.id_mark === props.markId ? <ListItem className={classes.listItem} key={note.id} alignItems="flex-start" divider={true}>
        <ListItemText className={classes.listItemText}
          primary={
            <React.Fragment>
              <Typography
                component="span"
                variant="caption"
                color="textPrimary"
                className={classes.author}
              >
                {note.user_name}
              </Typography>
              {note.text}
            </React.Fragment>
          }
        />
        </ListItem>
      : ""
      ) }
    </List>
  );
});

export default NoteList;