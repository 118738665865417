import { types } from "mobx-state-tree";

export const User = types
  .model("User", {
    isLogged: types.boolean,
    email: types.optional(types.string, ""),
    isActive: types.boolean,
  })
  .actions(self => ({
    updateEmail(newEmail) {
      self.email = newEmail
    }
  }));