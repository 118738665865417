import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../components/Navbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link as RouterLink, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#F4F6F6',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: 74,
  },
  button: {
    marginTop: 20,
  },
}));

export default function AccountConfirmation() {
  const classes = useStyles();
  let { token } = useParams();
  const [ state, setState ] = useState('init');
  const [ disabled, setDisabled ] = useState(false);

  const handleClick = (event) => {
    setDisabled(true);

    fetch(process.env.REACT_APP_API_URL + '/users/resend-confirmation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({token: token})
    })
      .then(r => r.json())
      .then(r => {
        if ('success' === r.status) {
        } else {
          console.error("Failed to confirm account", r)
        }
      })
      .catch(err => {
        console.error("Failed to confirm account", err)
      })
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/users/account-confirmation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({token: token})
    })
      .then(r => r.json())
      .then(r => {
        if ('success' === r.status) {
          setState('done');
        } else {
          setState('error');
          console.error("Failed to confirm account", r)
        }
      })
      .catch(err => {
        setState('error');
        console.error("Failed to confirm account", err)
      })
  }, []);

  return (
    <div className={classes.root}>
      <Navbar />
      {state === 'init' ?
        <CircularProgress />
      : state === 'done' ?
        <div className={classes.content}>
          <Typography variant="subtitle1">
            Поздравляем! Ваш аккаунт был успешно активирован, для входа используйте email и пароль, указанные при регистрации.
          </Typography>
          <Button className={classes.button} variant="outlined" component={RouterLink} to="/" color="primary" size="small">Перейти на главную страницу</Button>
        </div>
        :
        <div className={classes.content}>
        <Typography variant="subtitle1">
          Аккаунт не может быть активирован. Возможно срок жизни ссылки истек, попробуйте запросить повторно письмо с активацией.
        </Typography>
        <Button className={classes.button} variant="outlined" onClick={handleClick} color="primary" size="small" disabled={disabled}>Отправить письмо с активацией</Button>
        </div>
      }
   </div>
  );
}