import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../components/Navbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link as RouterLink, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { observer } from "mobx-react-lite";
import { useMst } from "../models/Root";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#F4F6F6',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: 74,
  },
  button: {
    marginTop: 20,
  },
  label: {
    marginTop: 10,
  },
}));

const AccountCreating = observer(() => {
  const classes = useStyles();
  const initTimer = 10000;
  const [ state, setState ] = useState('init');
  const [ disabled, setDisabled ] = useState(false);
  const [countdown, setCountdown] = useState(initTimer);
  const { user } = useMst();

  const startCountdown = (interval) => {
    let newValue = interval;

    function updateClock() {
      if (newValue !== initTimer && newValue <= 0) {
        clearInterval(timeinterval);
        setDisabled(false);
      } else {
        newValue = newValue - 1;
        setCountdown(newValue);
      }
    }
  
    updateClock();
    let timeinterval = setInterval(updateClock, 1000);
  }

  const handleClick = (event) => {
    setDisabled(true);

    startCountdown(60);

    fetch(process.env.REACT_APP_API_URL + '/users/resend-confirmation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({email: user.email})
    })
      .then(r => r.json())
      .then(r => {
        if ('success' === r.status) {
        } else {
          console.error("Failed to confirm account", r)
        }
      })
      .catch(err => {
        console.error("Failed to confirm account", err)
      })
  };

  return (
    <div className={classes.root}>
      <Navbar />
        <div className={classes.content}>
          <Typography variant="subtitle1">
            Ваш аккаунт ещё не активирован. Для активации пройдите по ссылке, которую мы выслали Вам на email. В случае отсутствия письма, проверьте папку "спам" или запросите письмо с активацией повторно.
          </Typography>
          <Button className={classes.button} variant="outlined" onClick={handleClick} color="primary" size="small" disabled={disabled}>Отправить письмо с активацией </Button>
          {countdown !== initTimer && countdown > 0 ?
          <Typography className={classes.label} variant="body2">
            повторная отправка возможна через {countdown} секунд 
          </Typography>
          : 
          ""
          }
          </div>
   </div>
  );
});

export default AccountCreating;