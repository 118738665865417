import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { observer } from "mobx-react-lite";
import { isValidReference } from "mobx-state-tree";
import { useMst } from "../models/Root";
import { useValidateUser } from './AuthProvider';
import Comments from "./Comments";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    minWidth: 240,
  },
  accessAllow: {
    marginTop: 8,
    marginBottom: 8,
    color: 'green',
  },
  accessDeny: {
    marginTop: 8,
    marginBottom: 8,
    color: 'red',
  },
  title: {
    fontSize: 14,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: 64,
    // maxWidth: 'calc(100% - 300px)',
  },
  annotation: {
    marginBlockStart: '-1rem',
    marginBlockEnd: '-1rem',
  },
  edit_now: {
    paddingLeft: 6,
  },
  get_link: {
    marginLeft: 4,
  },
  showContent: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  readButton: {
    marginRight: 8
  },
}));

const Mainbar = observer(() => {
  const classes = useStyles();
  const { book, mobile, tree } = useMst();
  const [share, setShare] = useState(null);
  const validUser = useValidateUser('ADMIN AUTHOR BETA_READER MODERATOR');

  const handleShowContentClick = () => {
    mobile.toggle();
  };

  useEffect(() => {
    if (isValidReference(() => book.selectedStory)) {
      if (book.selectedStory.id === 55 || (!validUser && book.selectedStory.draft !== 0)) {
        setShare(null);
        return;
      }

      const u = new URL(window.location.href);
      const link = u.origin + '/story/' + book.selectedStory.public_id;
      const content = {
        title: book.selectedStory.name + ' (Eny Gyoss)',
        description: 'Маленькие истории о Большем...',
        url: link
      }

      if (share === null || share === undefined) {
        setShare(window.Ya.share2('yandex-share', {
          theme: { lang: 'ru',
                  useLinks: true, 
                  size: 'm', 
                  moreButtonType: 'short', 
                  popupDirection: 'auto', 
                  popupPosition: 'outer', 
                  limit: '0', 
                  services: 'messenger,vkontakte,odnoklassniki,telegram,twitter,viber,whatsapp,moimir,skype'
          },
          content: content
        }));
      } else {
        share.updateContent(content);
      }
    }
  }, [tree.selected]);

  return (
      <main className={classes.content}>
        {isValidReference(() => book.selectedStory) && book.selectedStory.id_parent > 0 ?
        <Card className={classes.cardRoot} variant="outlined">
        <CardContent>
        <Link className={classes.showContent} onClick={handleShowContentClick} component="button" variant="h6">
          Открыть список историй
        </Link>
          <Typography variant="h6">
            {book.selectedStory.name}
          </Typography>
          <Typography variant="body2">
            Опубликовано: {book.selectedStory.date_create}
          </Typography>
          <Typography variant="body2">
            Обновлено: {book.selectedStory.date_update}
          </Typography>
          <Typography variant="body2">
            Страниц: {book.selectedStory.page_count}
          </Typography>
          <Typography variant="body2">
            Статус: {book.selectedStory.edit_status_text !== "" ? book.selectedStory.edit_status_text : "не определён"}
          </Typography>
          {(validUser || book.selectedStory.draft === 0) ?
            ""
            :
            <Typography className={classes.accessDeny} variant="subtitle2">
                В работе, ожидает редакции
            </Typography>
          }
          {book.selectedStory.annotation.length > 0 ?
            <div>
              <Typography variant="subtitle2">
                Аннотация
              </Typography>
              <Typography className={classes.annotation} variant="body2" component="p" dangerouslySetInnerHTML={{__html:  book.selectedStory.annotation}}/>
            </div>
            : ""
          }
        </CardContent>
        <CardActions>
          {(validUser || book.selectedStory.draft === 0) ?
            <div className={classes.buttons}>
              <Button className={classes.readButton} variant="outlined" component={RouterLink} to={book.readerLink} color="primary" size="medium">Приступить к чтению</Button>
              <div id="yandex-share"></div>
            </div>
            :
            <Button variant="outlined" color="primary" size="small" disabled>Пока не доступно</Button>
          }
          </CardActions>
        </Card>
        :
        <Card  className={classes.cardRoot} variant="outlined">
          <CardContent>
            <Typography variant="h5">
              Вкус притяжения
            </Typography>
            <Link className={classes.showContent} onClick={handleShowContentClick} component="button" variant="h6">
              Открыть список историй
            </Link>
            <Typography variant="body2" component="p" dangerouslySetInnerHTML={{__html:  isValidReference(() => book.selectedStory) ? book.selectedStory.annotation : ""}}/>  
          </CardContent>
        </Card>}
        <Comments />
      </main>
  );
});

export default Mainbar;