import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Navbar  from '../components/reader/Navbar';
import Mainbar  from '../components/reader/Mainbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'whitesmoke',
    textAlign: 'center',
  },
}));

export default function Story() {
  const classes = useStyles();
  let { public_id } = useParams();
  
  return (
    <div className={classes.root}>
      <Navbar public={true}/>
      <Mainbar storyId={public_id} public={true}/>
   </div>   
  );
}