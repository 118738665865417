import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { observer } from "mobx-react-lite";

const useStyles = makeStyles((theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    minWidth: 550,
    maxWidth: 550,
    maxHeight: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexWrap: 'wrap',
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  cardContent: {
    padding: '10px',
    '&:last-child': {
      paddingBottom: '10px',
    }
  },
  treeContent: {
    marginTop: '8px',
    marginBottom: '8ox',
    overflow: 'auto',
  },
  tree: {
    minHeight: 500,
    maxHeight: 500,
    flexGrow: 1,
    minWidth: 510,
    maxWidth: 510,
  },
  linkHeder: {
    marginBlockStart: 0,
    marginBlockEnd: '0.5em',
  },
  button: {
    marginBlockStart: '0.5rem',
    marginRight: '0.5rem',
  },
  footer: {
    textAlign: 'right',
    minWidth: 535,
    maxWidth: 535,
  }
}));

const SelectStory = observer((props) => {
  const classes = useStyles();
  const [valid, setValid] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState(0);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleCancel = () => {
    props.onClose(null);
  };

  const handleAccept = () => {
    props.onClose(selected);
  };

  const validateForm = () => {  
    if (selected > 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  useEffect(() => {
    setSelected(props.storyId);
  }, [props.open]);

  useEffect(() => {
    validateForm();
  }, [selected]);

  const renderTree = (nodes) => {
    return (
      <TreeItem key={String(nodes.id)} nodeId={String(nodes.id)} label={nodes.name}>
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    )
  };

  return (
    <Modal
      open={props.open}
      onClose={handleCancel}
      aria-labelledby="dic-modal-title"
      aria-describedby="dic-modal-list"
    >
      <Card className={classes.paper} variant="outlined">
        <CardContent className={classes.cardContent}>
          <h4 id="dic-modal-title" className={classes.linkHeder}>
            Выбор истории
          </h4>
          <Divider />
          <div className={classes.treeContent}>
            <TreeView
              className={classes.tree}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
            >
              {renderTree(props.treeData[0])}
            </TreeView>
          </div>
          <div className={classes.footer}>
            <Button className={classes.button} onClick={handleAccept} disabled={!valid} variant="outlined" color="primary" size="small">
                Принять
            </Button>
            <Button className={classes.button} onClick={handleCancel} variant="outlined" color="primary" size="small">
                Отмена
            </Button>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
});

export default SelectStory;