import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react-lite";
import { useMst } from "../models/Root";
import Comment from "./Comment";

const useStyles = makeStyles((theme) => ({
    Root: {
      marginTop: 8,
    }
}));

const CommentTree = observer(() => {
  const classes = useStyles();
  const { book } = useMst();

  return (
    <div className={classes.Root}>
      {book.selectedStory.comments.map((comment, index) => 
        <Comment
          data={comment}
          key={index}
          index={index}
        />)
      }
    </div>
  );
});

export default CommentTree;