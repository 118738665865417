import { Previewer } from 'pagedjs';
import { donatLink, donatQRCode, donatCard } from '../utils/constants'

function clearAttribute(parentNode) {
  for (let node of parentNode.children) {
    for (var key in node.dataset) {
      if (key !== 'splitTo') {
        node.removeAttribute("data-" + key.split(/(?=[A-Z])/).join("-").toLowerCase());
      }
    }

    if (node.children.length > 0) {
      clearAttribute(node);
    }
  }
}

export function pageCut(header, text, story, isPublic, needDonat, setCurrentReadingStory) {
    let newText = '<div style="display: flex; justify-content: space-between"><h4 style="display: flex">Eny Gyoss</h4>' +
    '<a href="https://gyoss.ru"><h4 style="display: flex">gyoss.ru</h4></a></div>' +
    '<h2 class="story-header">' + header + '</h2>' + text;
    if (needDonat) {
      newText = newText + '<h4><a href="' + donatLink + '" target="_blank">Отблагодарить, помочь проекту.</a></h4>' +
                          '<img src="' + donatQRCode + '" alt="qrCode" width="150" height="150"/>' +
                          '<p>Номер карты: ' + donatCard + '</p>';
    }

    story.text = newText;
    // console.log(newText);
    setCurrentReadingStory(story);   
}

// export function pageCut(header, text, story, isPublic, needDonat, setCurrentReadingStory) {
//   let paged = new Previewer();

//   let html = (new DOMParser()).parseFromString(text, "text/html")
//   let content = html.querySelectorAll('body > *');
//   let fragment = document.createDocumentFragment();
//   for (let i = 0; i < content.length; i++) {
//     fragment.appendChild(content[i]);
//   }

//   let el = document.createElement('div');
//   el.setAttribute('class', 'content')
//   el.style.visibility = 'hidden';
//   document.body.append(el);
//   paged.preview(fragment, [], el).then((flow) => {
//     let draft = el.cloneNode(true);
//     let newText = '<div style="display: flex; justify-content: space-between"><h4 style="display: flex">Eny Gyoss</h4><a href="https://gyoss.ru"><h4 style="display: flex">gyoss.ru</h4></a></div>';
//     // let newText = isPublic ? '<div style="display: flex; justify-content: space-between"><h4 style="display: flex">Eny Gyoss</h4><a href="https://gyoss.ru"><h4 style="display: flex">gyoss.ru</h4></a></div>' : '';
//     // let newText = isPublic ? '<div style="display: flex; justify-content: space-between"><h4 style="display: flex">Eny Gyoss</h4></div>' : '';
//     newText = newText + '<h2 class="story-header">' + header + '</h2>';
//     // let newText = '<h2 class="story-header">' + header + '</h2>';
//     el.remove();
//     for (let i = 0; i < draft.firstElementChild.children.length; i++) {
//       let page = draft.firstElementChild.children[i].firstElementChild.lastElementChild.lastElementChild.firstElementChild.firstElementChild;
//       clearAttribute(page);

//       newText += '<div class="page-number">- ' + String(i + 1) + ' -</div>' + page.outerHTML.slice(5, page.outerHTML.length - 6); 
//     }

//     newText = newText + '<h4><a href="' + donatLink + '" target="_blank">Отблагодарить, помочь проекту.</a></h4>' +
//     '<img src="' + donatQRCode + '" alt="qrCode" width="150" height="150"/>';

//     story.text = newText;
//     setCurrentReadingStory(story);   
//     draft.remove();
//   })

//   return text;
// }