import { types } from "mobx-state-tree";

export const Mobile = types
  .model({
    open: types.boolean,
  })
  .actions(self => ({
    toggle() {
      self.open = !self.open;
    }
  }));