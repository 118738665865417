import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SignUp from '../components/SignUp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

export default function SignUpPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SignUp />
   </div>
  );
}