import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../components/Navbar';
import Leftbar from '../components/Leftbar';
import Mainbar from '../components/Mainbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#F4F6F6',
    height: '100vh',
  },
}));

export default function Home() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Navbar />
      <Leftbar />
      <Mainbar />
   </div>
  );
}