import { useContext, createContext } from "react";
import { types } from "mobx-state-tree";
import { Mobile } from "./Mobile";
import { Book } from "./Story";
import { BookDraft } from "./StoryDraft";
import { Tree } from "./Tree";
import { User } from "./User";
import { Dics } from "./Dic";

const RootModel = types.model({
  mobile: Mobile,
  tree: Tree,
  book: Book,
  bookDraft: BookDraft,
  user: User,
  dics: Dics,
});

const initialState = RootModel.create({
  mobile: {
    open: false
  },
  tree: {
    expanded: [],
    selected: 0,
  },
  book: {
    state: "init",
    stories: [],
    currentReadingStory: {},
  },
  bookDraft: {
    state: "init",
    stories: [],
  },
  user: {
    isLogged: false,
    email: "",
    isActive: false,
  },
  dics: {
    items: [],
  },
});

// const data = localStorage.getItem('rootState');
// if (data) {
//   const json = JSON.parse(data);
//   if (RootModel.is(json)) {
//     initialState = RootModel.create(json);
//   }
// }

export const rootStore = initialState;

// onSnapshot(rootStore, snapshot => {
//   console.log("Snapshot: ", snapshot);
//   localStorage.setItem('rootState', JSON.stringify(snapshot));
// });

const RootStoreContext = createContext(null);

export const Provider = RootStoreContext.Provider;
export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}