import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { observer } from "mobx-react-lite";
import { useMst } from "../models/Root";

const useStyles = makeStyles((theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const DicEditor = observer((props) => {
  const classes = useStyles();
  const { dics } = useMst();
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (props.dicName !== '') {
      setItems(dics.getDic(props.dicName));
    }
  }, [props.dicName]);

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="dic-modal-title"
      aria-describedby="dic-modal-list"
    >
      <div className={classes.paper}>
        <h2 id="dic-modal-title">Text in a modal</h2>
        <p id="dic-modal-list">
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </p>
      </div>
    </Modal>
  );
});

export default DicEditor;