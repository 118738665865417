import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import HomeIcon from '@material-ui/icons/Home';
import BackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import MarkIcon from '@material-ui/icons/BorderColorOutlined';
import UnMarkIcon from '@material-ui/icons/FormatClearOutlined';
import MarksIcon from '@material-ui/icons/ChatOutlined';
import SynonymsIcon from '@material-ui/icons/SchoolOutlined';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { authFetch, useValidateUser } from '../../components/AuthProvider';
import { isValidReference } from "mobx-state-tree";
import { useMst } from "../../models/Root";
import Tooltip from '@material-ui/core/Tooltip';
import MarkNotes from './MarkNotes';
import SynonymsList from '../Synonyms';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { generateUUID } from '../../utils/utils';

function changeOrder(match, beginSpan, spanStyle, spanId) {
  return beginSpan + spanId + spanStyle;
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolBarTitle: {
      flexGrow: 1,
    },
    colorPicker: {
      position: 'absolute',
      top: '66px',
      left: '74px',
      default: {
        picker: { // See the individual picker source for which keys to use
          input: 'none',
        },
      },
    },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    boxShadow: '0.4em 0.4em 5px rgba(122,122,122,0.5)',	
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    height: '30px',
    margin: '4px',
    borderRadius: '10p',
  },
}))(MenuItem);

export default function Navbar(props) {
    const classes = useStyles();
    const { book } = useMst();
    const history = useHistory();
    const [openNotes, setOpenNotes] = useState(false);
    const [openSynonyms, setOpenSynonyms] = useState(false);
    const [input, setInput] = useState('');
    const [markId, setMarkId] = useState('');
    const validUser = useValidateUser('ADMIN AUTHOR BETA_READER MODERATOR');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRange, setcurrentRange] = useState(null);
    const colors = ['#ff784e', '#f1c40f', '#A4DD00', '#68CCCA', '#AEA1FF', '#FDA1FF', '#cccccc'];
  
    const handleCloseMarkNotes = () => {
      setOpenNotes(false);
    };

    const handleCloseColor = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (event, index) => {
      setAnchorEl(null);
      let mark_id = '';
      let selection = document.getSelection();
      if (currentRange !== null) {
        let range = currentRange;
        if (range.startContainer === range.endContainer && range.startOffset !== range.endOffset && !book.currentReadingStory.empty) {
          if (range.startContainer.parentNode.nodeName === 'SPAN') {
            if (range.startContainer.parentNode.getAttribute('id') === null) {
              mark_id = generateUUID();
              range.startContainer.parentNode.setAttribute("id", mark_id);
            } else {
              mark_id = range.startContainer.parentNode.getAttribute('id');
            }
            range.startContainer.parentNode.style.backgroundColor = colors[index];
          } else {
            let span = document.createElement('span');
            mark_id = generateUUID();
            span.setAttribute("id", mark_id)
            span.style.backgroundColor = colors[index];
            range.surroundContents(span);
          }
          selection.removeAllRanges();
          selection.addRange(range);
          const searchRegExp = /<br>/g;
          const replaceWith = '<br />';
          book.currentReadingStory.updateText(document.getElementById('content').innerHTML.replace(searchRegExp, replaceWith));
          save(mark_id);
        }
        setcurrentRange(null);
      }
    };

    const handleMark = (event) => {
      let selection = document.getSelection();
      if (selection.rangeCount > 0) {
        let range = selection.getRangeAt(0);
        if (range.startContainer === range.endContainer && range.startOffset !== range.endOffset && !book.currentReadingStory.empty) {
          setcurrentRange(range.cloneRange());
          setAnchorEl(event.currentTarget);
        }
      }
    }

    const handleUnMark = () => {
      let selection = document.getSelection();
      if (selection.rangeCount > 0) {
        let range = selection.getRangeAt(0);
        if (range.startContainer === range.endContainer) {
          if (range.startContainer.parentNode.nodeName === 'SPAN' && !book.currentReadingStory.empty) {
            range.startContainer.parentNode.replaceWith(range.startContainer.parentNode.textContent);
            const searchRegExp = /<br>/g;
            const replaceWith = '<br />';
            book.currentReadingStory.updateText(document.getElementById('content').innerHTML.replace(searchRegExp, replaceWith));
            save('');
          }
        }
      }
    }

    const handleMarks = () => {
      let selection = document.getSelection();
      if (selection.rangeCount > 0) {
        let range = selection.getRangeAt(0);
        if (range.startContainer === range.endContainer) {
          if (range.startContainer.parentNode.nodeName === 'SPAN' && !book.currentReadingStory.empty) {
            if (range.startContainer.parentNode.getAttribute('id') === null) {
              const mark_id = generateUUID();
              range.startContainer.parentNode.setAttribute("id", mark_id);
              const searchRegExp = /<br>/g;
              const replaceWith = '<br />';
              book.currentReadingStory.updateText(document.getElementById('content').innerHTML.replace(searchRegExp, replaceWith));
              save(mark_id);
            }
            setMarkId(range.startContainer.parentNode.getAttribute('id'));
            setOpenNotes(true);
          }
        }
      }
    }

    const handleOpenSynonyms = (event) => {
      let selection = document.getSelection();
      if (selection.rangeCount > 0) {
        let range = selection.getRangeAt(0);
        if (range.startContainer === range.endContainer && range.startOffset !== range.endOffset && !book.currentReadingStory.empty) {
          setInput(selection.toString().trim());
        } else {
          setInput('');  
        }
      } else {
        setInput('');
      }
      setOpenSynonyms(true);
    }

    const handleCloseSynonyms = () => {
      setOpenSynonyms(false);
    };

    const handleGoHome = () => {
      history.push('/');
    }

    const save = (mark_id) => {
      let searchRegExp = /<br>/g;
      let replaceWith = '<br />';
      const pageNumber = /<div class="page-number">- \d+? -<\/div>/g;
      const storyHeader = /<h2 class="story-header">.+?<\/h2>/g;
      let newText = document.getElementById('content').innerHTML.replace(searchRegExp, replaceWith).replace(pageNumber, '').replace(storyHeader,'');

      searchRegExp = /—/g;
      replaceWith = '&mdash;';
      newText = newText.replace(searchRegExp, replaceWith);

      searchRegExp = /–/g;
      replaceWith = '&ndash;';
      newText = newText.replace(searchRegExp, replaceWith);

      searchRegExp = /…/g;
      replaceWith = '&hellip;';
      newText = newText.replace(searchRegExp, replaceWith);

      searchRegExp = /«/g;
      replaceWith = '&laquo;';
      newText = newText.replace(searchRegExp, replaceWith);

      searchRegExp = /»/g;
      replaceWith = '&raquo;';
      newText = newText.replace(searchRegExp, replaceWith);

      let newColor = '#ff784e';
      let rgbColor = /rgb\(255, 120, 78\)/g;
      newText = newText.replace(rgbColor, newColor);
      newColor = '#f1c40f';
      rgbColor = /rgb\(241, 196, 15\)/g;
      newText = newText.replace(rgbColor, newColor);
      newColor = '#a4dd00';
      rgbColor = /rgb\(164, 221, 0\)/g;
      newText = newText.replace(rgbColor, newColor);
      newColor = '#68ccca';
      rgbColor = /rgb\(104, 204, 202\)/g;
      newText = newText.replace(rgbColor, newColor);
      newColor = '#aea1ff';
      rgbColor = /rgb\(174, 161, 255\)/g;
      newText = newText.replace(rgbColor, newColor);
      newColor = '#fda1ff';
      rgbColor = /rgb\(253, 161, 255\)/g;
      newText = newText.replace(rgbColor, newColor);
      newColor = '#cccccc';
      rgbColor = /rgb\(204, 204, 204\)/g;
      newText = newText.replace(rgbColor, newColor);

      newText = newText.replace(/(<span)( style="background-color: #.{6};")( id=".{36}")/gus, changeOrder)

      if (newText.includes('data-split-to')) {
        let pos = -1;
        while ((pos = newText.indexOf('data-split-to', pos + 1)) != -1) {
          const divPos = newText.lastIndexOf('<div', pos);
          const pPos = newText.lastIndexOf('<p', pos);
          let node = '';
          if (divPos > pPos) {
            node = 'div';
          } else {
            node = 'p';
          }
          const closePos = newText.indexOf('</' + node, pos);
          const openPosStart = newText.indexOf('<' + node, pos);
          const openPosEnd = newText.indexOf('>', openPosStart);
          newText = newText.slice(0, closePos) + newText.slice(closePos + node.length + 3, openPosStart) + newText.slice(openPosEnd + 1);
        }

        const split = / data-split-to=".+?"/g;
        newText = newText.replace(split, '');
      }
      
      // book.compareText(newText);

      let marked = 0;
      // if (storyId !== 0 && !emptyText && text !== newText) {
      if (props.storyId !== 0) {
        if (newText.includes('<span id=') || newText.includes('<span style="background-color:')) {
          marked = 1;
        } else {
          marked = 0;
        }

        let story = {text: newText,
                     marked: marked};

        authFetch(process.env.REACT_APP_API_URL + '/stories/' + props.storyId, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(story)
        })
          .then(r => r.json())
          .then(r => {
            if ('success' === r.status) {
              if (mark_id.length > 0) {
                setMarkId(mark_id);
                setOpenNotes(true);
              }
            } else {
              console.error("Failed to save book", r)
            }
          })
          .catch(err => {
            console.error("Failed to save book", err)
        })
      }
    };

    return (
      <div>
      {/* {validUser && !props.public ? 
        <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar>
        <Tooltip title="Вернуться назад">
          <IconButton
            variant="outlined"
            color="primary"
            onClick={history.goBack}
          >
            <BackIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Выделить">
          <IconButton
            color="primary"
            onClick={handleMark}
            variant="outlined"
          >
            <MarkIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Убрать выделение">
          <IconButton
            color="primary"
            onClick={handleUnMark}
            variant="outlined"
          >
            <UnMarkIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Комментарии к выделенному тексту">
          <IconButton
            color="primary"
            onClick={handleMarks}
            variant="outlined"
          >
            <MarksIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title="Словарь синонимов">
          <IconButton
            color="primary"
            onClick={handleOpenSynonyms}
            variant="outlined"
          >
            <SynonymsIcon />
          </IconButton>
          </Tooltip>
        </Toolbar>
        <MarkNotes open={openNotes} onClose={handleCloseMarkNotes} markId={markId}/>
        <SynonymsList open={openSynonyms} handleClose={handleCloseSynonyms} input={input}/>
        <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseColor}
      >
        {colors.map((color, index) => (
          <StyledMenuItem
            key={color}
            onClick={(event) => handleMenuItemClick(event, index)}
            style={{backgroundColor: color}}
          >
          </StyledMenuItem>
        ))}

      </StyledMenu>

      </AppBar>
        : */}
      <HideOnScroll {...props}>
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar variant="dense">
          <Tooltip title="На главную страницу">
              <IconButton
                variant="outlined"
                color="primary"
                onClick={handleGoHome}
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Вернуться назад">
              <IconButton
                variant="outlined"
                color="primary"
                onClick={history.goBack}
              >
                <BackIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {/* } */}
      </div>
    );
}