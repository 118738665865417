import { types } from "mobx-state-tree";

export const Tree = types
  .model({
    expanded: types.array(types.string),
    selected: types.optional(types.number, 0),
    toggle: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setExpanded(ex) {
      self.expanded = ex;
    },
    setSelected(sel) {
      self.selected = sel;
    },
    setToggle(tog) {
      self.toggle = tog;
    }
  }));