import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Reader from './pages/Reader';
import Editor from './pages/Editor';
import Moderator from './pages/Moderator';
import Story from './pages/Story';
import AccountConfirmation from './pages/AccountConfirmation';
import AccountCreating from './pages/AccountCreating';
import { Provider, rootStore } from "./models/Root";
import { useAuth, login, getRole } from './components/AuthProvider';
import CircularProgress from '@material-ui/core/CircularProgress';

function App() {
    return (
      <Provider value={rootStore}>
        <Router>
          <Switch>
          <PublicRoute path="/account-confirmation/:token">
                <AccountConfirmation/>
            </PublicRoute>
            <PublicRoute path="/account-creating">
                <AccountCreating/>
            </PublicRoute>
            <PublicRoute path="/login">
                <Login/>
            </PublicRoute>
            <PublicRoute path="/sign-up">
                <SignUp/>
            </PublicRoute>
            <PublicRoute path="/story/:public_id">
                <Story/>
            </PublicRoute>
            <PrivateRoute path="/reader/:id" roles="ADMIN AUTHOR BETA_READER MODERATOR">
                <Reader />
            </PrivateRoute>
            <PrivateRoute path="/editor" roles="ADMIN AUTHOR">
                <Editor />
            </PrivateRoute>
            {/* <PrivateRoute path="/moderator" roles="ADMIN AUTHOR MODERATOR">
                <Moderator />
            </PrivateRoute> */}
            <PublicRoute path="/">
                <Home/>
            </PublicRoute>
          </Switch>
        </Router>
      </Provider>
    );
}

function PrivateRoute({ children, ...rest }) {
    const [logged] = useAuth();
    const [auth, setAuth] = useState('undefined');

    useEffect(() => {
        if (logged) {
            if (rest.roles && rest.roles.length > 0) {
                let roles = rest.roles.split(' ');
                if (roles.indexOf(getRole()) !== -1) {
                    setAuth('yes');                    
                } else {
                    setAuth('no');
                }
            } else {
                setAuth('yes');
            }
        } else {
            fetch(process.env.REACT_APP_API_URL + '/users/update-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                credentials: 'include'
            })
            .then(r => r.json())
            .then(r => {
                if ('success' === r.status) {
                    login(r.message);
                    if (rest.roles && rest.roles.length > 0) {
                        let roles = rest.roles.split(' ');
                        if (roles.indexOf(getRole()) !== -1) {
                            setAuth('yes');                    
                        } else {
                            setAuth('no');
                        }
                    } else {
                        setAuth('yes');
                    }
                } else {
                    setAuth('no');
                }
            })
            .catch(err => {
                console.error(err);
                setAuth('no');
            })
        }
    }, [])

    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth === 'undefined' ? (<CircularProgress />) : (auth === 'yes' ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          ))
        }
      />
    );
  }

function PublicRoute({ children, ...rest }) {
    const [logged] = useAuth();
    const [auth, setAuth] = useState('undefined');

    useEffect(() => {
        if (logged) {
            setAuth('yes');
        } else {
            fetch(process.env.REACT_APP_API_URL + '/users/update-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                credentials: 'include'
            })
            .then(r => r.json())
            .then(r => {
                if ('success' === r.status) {
                    login(r.message);
                    setAuth('yes');
                } else {
                    setAuth('no');
                }
            })
            .catch(err => {
                console.error(err);
                setAuth('no');
            })
        }
    }, [])

    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth === 'undefined' ? (
            <CircularProgress />
          ) : (
            children
          )
        }
      />
    );
  }

export default App;
