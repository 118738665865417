import {
    types,
    // Instance,
    // SnapshotIn,
    getParent,
    destroy,
    flow,
    // applySnapshot,
    // getSnapshot
  } from "mobx-state-tree";
  
  export const StoryDraft = types
    .model("StoryDraft", {
      id: types.identifierNumber,
      id_parent: types.optional(types.number, 0),
      name: types.optional(types.string, ""),
      annotation: types.optional(types.string, ""),
      text: types.optional(types.string, ""),
      date_create: types.optional(types.string, ""),
      date_update: types.optional(types.string, ""),
      children: types.array(types.late(() => StoryDraft)),
      expanded: types.optional(types.boolean, false),
    })
    .actions(self => {
      function changeName(newName) {
        self.name = newName;
      }

      function changeAnnotation(newAnnotation) {
        self.annotation = newAnnotation;
      }

      function changeText(newText) {
        self.text = newText;
      }

      function removeSelf() {
        getParent(self, 2).remove(self);
      }

      function addStory(storyDraft) {
        self.children.push(storyDraft);
      }

      function remove(storyDraft) {
        destroy(storyDraft);
      }

      function updateChildren(json) {
        self.children.clear()

        json.forEach(storyJson => {
          self.children.push(storyJson)
        })
        console.log(self.children[0]);
        // console.log(self.children[0].id, self.children[0].name)
      }

      const loadChildren = flow(function* loadChildren() {
          try {
            const json = yield fetch(
                              process.env.REACT_APP_API_URL + `/stories/children/` + self.id, {
                              method: 'GET',
                              headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                              }
                          }
                        ).then(response => response.json()) 
              updateChildren(json.message)
              // markLoading(false)
          } catch (err) {
              console.error("Failed to load books ", err)
          }
      })

      return {
          addStory,
          changeName,
          changeText,
          changeAnnotation,
          removeSelf,
          remove,
          updateChildren,
          loadChildren
      }
    })

    export const BookDraft = types
    .model("BookDraft", {
      state: types.enumeration("State", ["init", "pending", "done", "error"]),
      stories: types.array(StoryDraft),
      expandingStory: types.optional(types.reference(StoryDraft), 0),
      selectedStory: types.optional(types.reference(StoryDraft), 0),
    })
    .actions(self => {
      function addStory(storyDraft) {
        self.stories.push(storyDraft);
      }

      function remove(storyDraft) {
        destroy(storyDraft);
      }

      function selectStory(nodeId) {
        self.selectedStory = nodeId;
      }

      function updateStories(json) {
        self.stories.clear()

        json.forEach(storyJson => {
          self.stories.push(storyJson)
          if (storyJson['children'].length > 0) {
            self.selectedStory = storyJson['id'];
            self.selectedStory.updateChildren(storyJson['children']);
          }
        })
      }

      const loadAllStories = flow(function* loadAllStories() {
        if (self.state !== "pending") {  
          self.state = "pending"
          try {
            const json = yield fetch(
                              process.env.REACT_APP_API_URL + `/stories`, {
                              method: 'GET',
                              headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                              }
                          }
                        ).then(response => response.json()) 
              updateStories(json.message)
              self.state = "done"
              self.selectedStory = self.stories[0].id;
          } catch (err) {
              console.error("Failed to load books ", err)
              self.state = "error"
          }
        }
      })

      return {
          addStory,
          remove,
          selectStory,
          loadAllStories,
          updateStories
      }
    })