import React, { useState, useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import NewIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { authFetch } from './AuthProvider';
import SynonymEditor from './SynonymEditor';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  table: {
    minWidth: 400,
    marginTop: '10px',
  },
  tableCell: {
    fontSize: 'large',
  },
  synonym: {
  },
  space: {
    display: 'flex',
    width: '100%'
  },
  buttonClose: {
    marginRight: '8px'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 'large',
  },
  helperText: {
    color: 'red',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function SynonymsList(props) {
  const classes = useStyles();
  const theme = useTheme();
  const inputField = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [selIndex, setSelIndex] = useState(-1);
  const [input, setInput] = useState('');
  const [rows, setRows] = useState([]);
  const [synonymsRow, setSynonymsRow] = useState(null);
  const [helperText, setHelperText] = useState('');
  const [openSynonymEditor, setOpenSynonymEditor] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearch = () => {
    setHelperText('');
    let value = inputField.current.value;
    value = value.replace(/[^a-zA-Zа-яА-ЯёЁ0-9,.!?'"\- ]/g, ""); 
    inputField.current.value = value;
    if (value.length >= 3) {
      search(value);
    } else {
      setHelperText('Для поиска укажите не менее трех букв.');
      setRows([]);
      setSelIndex(-1);
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSearch();
    }
  }

  const handleNewSynonyms = () => {
    setSynonymsRow(null);
    setOpenSynonymEditor(true);
  }

  const handleEditSynonyms = () => {
    setSynonymsRow(rows[selIndex]);
    setOpenSynonymEditor(true);
  }

  const handleCloseSynonymEditor = () => {
    setOpenSynonymEditor(false);
  };

  const handleAcceptSynonymsEdit = (newSet, newRows) => {
    setOpenSynonymEditor(false);

    let id = newSet ? '' : rows[selIndex].id;
    let value = newSet ? newRows[0] : inputField.current.value;
    let words = {
      words: newRows.join('#')
    };

    authFetch(process.env.REACT_APP_API_URL + '/synonyms/' + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(words)
    })
      .then(r => r.json())
      .then(r => {
        if ('success' === r.status) {
          search(value);
          inputField.current.value = value;
        } else {
          console.error("Failed to add synonyms", r)
        }
      })
      .catch(err => {
        console.error("Failed to add synonyms", err)
    })
  }

  const handleDeleteSynonyms = () => {
    let id = rows[selIndex].id;
    let value = inputField.current.value;

    authFetch(process.env.REACT_APP_API_URL + '/synonyms/' + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
      .then(r => r.json())
      .then(r => {
        if ('success' === r.status) {
          search(value);
        } else {
          console.error("Failed to add synonyms", r)
        }
      })
      .catch(err => {
        console.error("Failed to add synonyms", err)
    })
  }

  const handleRowClick = (id, index) => {
    setSelIndex(index)
  }

  const handleWordDblClick = (word) => {
    if (props.handleSelWord !== undefined) {
      props.handleSelWord(word);
    }
  }

  const search = (value) => {
    if (value.length < 3) {
      return;
    }

    authFetch(process.env.REACT_APP_API_URL + '/synonyms/' + value, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json;charset=utf-8'
        }
    })
        .then(r => r.json())
        .then(r => {
        if ('success' === r.status) {
          let synRows = [];
          r.message.forEach((synonyms) => {
            let words = synonyms.words.split('#');
            words.sort();
            let tempWords = words.slice();
            words.forEach((word, index) => {
              if (word.includes(value)) {
                tempWords.splice(index, 1)
                synRows.push({
                  index: synRows.length,
                  id: synonyms.id,
                  word: word,
                  words: tempWords
                });
                tempWords = words.slice();
              }
            })
          });
          setRows(synRows)
          if (synRows.length > 0) {
            setSelIndex(0);
            setHelperText('');
          } else {
            setSelIndex(-1)
            setHelperText('Ничего не найдено.');
          }
          setLoaded(true)
        } else {
            console.error("Failed to load synonyms", r)
        }
        })
        .catch(err => {
        console.error("Failed to load synonyms", err)
    })
  }

  useEffect(() => {
    setLoaded(false)
    setInput(props.input)

    if (props.open) {
      if (props.input.length >= 3) {
        search(props.input)
      } else {
        setRows([]);
        setSelIndex(-1);
        setHelperText('');
        setLoaded(true);
      }
    }
  }, [props.open, props.input]);

  return (
    <Dialog fullScreen={fullScreen} maxWidth='xl' onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open && loaded}>
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          Словарь синонимов
      </DialogTitle>
      <DialogContent dividers>
          <Paper className={classes.paper}>
            <InputBase
              size="small"
              inputRef={inputField}
              className={classes.input}
              placeholder="слово для поиска"
              defaultValue={input}
              inputProps={{ 'aria-label': 'search synonyms' }}
              onKeyDown={handleKeyDown}
            />
            <Tooltip title="Поиск синонимов">
              <IconButton autoFocus className={classes.iconButton} aria-label="search" onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Paper>
          <FormHelperText id="component-helper-text" className={classes.helperText} visibility={helperText.length > 0 ? "" : "hidden"}>{helperText}</FormHelperText>
          <TableContainer>
          <Table className={classes.table} aria-label="simple table" size="small">
              <TableBody>
              {rows.map((row) => (
                  <TableRow onClick={(event) => handleRowClick(row.id, row.index)} key={row.index} selected={row.index === selIndex} hover>
                      <TableCell className={classes.tableCell} align="left">
                        <span onDoubleClick={(event) => handleWordDblClick(row.word)}>{row.word}</span>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">{
                        row.words.map((word, index) => (
                          <span onDoubleClick={(event) => handleWordDblClick(word)} key={index} className={classes.synonym}>
                            {(index < row.words.length - 1) ? word + ' | ' : word}
                          </span>
                        ))}
                      </TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </DialogContent>
      <DialogActions disableSpacing={true}>
      <Tooltip title="Создать новый набор синонимов">
          <IconButton
            color="primary"
            onClick={handleNewSynonyms}
            variant="outlined"
          >
            <NewIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Редактировать выделенный набор синонимов">
          <span>
            <IconButton
              color="primary"
              onClick={handleEditSynonyms}
              variant="outlined"
              disabled={selIndex < 0}
            >
              <EditIcon/>
            </IconButton>
          </span>
        </Tooltip>  
        <Tooltip title="Удалить выделенный набор синонимов">
          <span>
            <IconButton
              color="primary"
              onClick={handleDeleteSynonyms}
              variant="outlined"
              disabled={selIndex < 0}
            >
              <DeleteIcon/>
            </IconButton>
          </span>
        </Tooltip>
        <div className={classes.space}></div>
        <Button onClick={props.handleClose} color="primary" className={classes.buttonClose}>
        Закрыть
        </Button>
      </DialogActions>
      <SynonymEditor 
        open={openSynonymEditor}
        handleClose={handleCloseSynonymEditor}
        handleAcceptSynonymsEdit={handleAcceptSynonymsEdit}
        synonymsRow={synonymsRow}
        inputValue={inputField.current !==null ? inputField.current.value : ''}
      />
    </Dialog>
  );
}