import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/Undo';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { useMst } from "../../models/Root";

const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolBarTitle: {
      flexGrow: 1,
      justifyContent: 'flex-start',
    },
    closeButton: {
      justifyContent: 'flex-end',
    },
}));

export default function Navbar(props) {
    const classes = useStyles();
    const history = useHistory();
    const { book, tree } = useMst();

    const onClose = () => {
        book.setState("init");
        tree.setSelected(0);
        tree.setExpanded([]);
        history.push("/");
    }

    return (
        <AppBar position="fixed" className={classes.appBar} color="secondary">
          <Toolbar variant="dense">
            <IconButton edge="start" color="inherit" onClick={props.onSaveClick}>
              <SaveIcon />
            </IconButton>
            <div  className={classes.toolBarTitle}>
            {/* <IconButton edge="start" color="inherit" onClick={props.onDiscardClick}>
              <CancelIcon />
            </IconButton> */}
            </div>
            <IconButton edge="end" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
            {/* <Button component={RouterLink} to="/" color="inherit">Завершить редактирование</Button> */}
           </Toolbar>
        </AppBar>
    );
}