import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/SettingsOutlined';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DicEditor from '../DicEditor';
import { observer } from "mobx-react-lite";
import { useMst } from "../../models/Root";

const useStyles = makeStyles((theme) => ({
  properties: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  editButton: {
    marginTop: '12px',
  },
}));

const Properties = observer((props) => {
  let location = useLocation();
  const classes = useStyles();
  const { dics } = useMst();
  const dic_edit_statuses = 'edit_statuses';
  const [open, setOpen] = useState(false);
  const [dicName, setDicName] = useState('');
  const [editStatuses, setEditStatuses] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [linkText, setLinkText] = useState('');

  const handleOpenDicEditor = (event) => {
    alert('Данная функция пока не доступна...');
    // setDicName(event.currentTarget.getAttribute ('data-dic-name'));
    // setOpen(true);
  };

  const handleCloseDicEditor = () => {
    setOpen(false);
  };

  const handleGetLink = () => {
    let u = new URL(window.location.href);
    const link = u.origin + '/story/' + props.public_id;
    navigator.clipboard.writeText(link);
    setLinkText('Публичная ссылка "' + link + '" скопирована в буфер обмена.');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setEditStatuses(dics.getDic(dic_edit_statuses));
  }, []);

  return (
    <div className={classes.properties}>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="status-select-label">Статус</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={props.edit_status}
            onChange={props.onEditStatusChange}
          >
            <MenuItem value="0" key="0">не определён</MenuItem>
            {editStatuses.map((dicItem) =>
              <MenuItem value={dicItem.id} key={dicItem.id}>{dicItem.text}</MenuItem>
            )}
          </Select>
        </FormControl>
        <Tooltip title="Редактировать список статусов">
          <IconButton aria-label="edit" className={classes.editButton} onClick={handleOpenDicEditor} data-dic-name={dic_edit_statuses}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </div>  
      <FormControlLabel
        control={
          <Checkbox
            checked={props.edit_now}
            onChange={props.onEditNowChange}
            name="checkedEditing"
            color="primary"
          />
        }
        label="Редактируется сейчас"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={props.draft}
            onChange={props.onDraftChange}
            name="checkedDraft"
            color="primary"
          />
        }
        label="Черновик"
      />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.public_allow}
              onChange={props.onPublicAllowChange}
              name="checkedPublicAllow"
              color="primary"
            />
          }
          label="Публичная"
        />
        <Button aria-label="get-link" disabled={!props.public_allow} onClick={handleGetLink} variant="outlined" color="primary" size="small">
          Получить ссылку
        </Button>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.need_donat}
            onChange={props.onNeedDonatChange}
            name="checkedNeedDonat"
            color="primary"
          />
        }
        label="Добавить ссылку на донат"
      />
      <DicEditor open={open} onClose={handleCloseDicEditor} dicName = {dicName}/>
      <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle id="draggable-dialog-title">
              Получение публичной ссылки
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {linkText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus disableFocusRipple={true} onClick={handleCloseDialog} color="primary">
                    Принять
                </Button>
            </DialogActions>
        </Dialog>
    </div>
  );
});

export default Properties;