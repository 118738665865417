import React, { Component } from 'react';
import SortableTree, { changeNodeAtPath, addNodeUnderParent, removeNode } from 'react-sortable-tree';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DelIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getUserId } from '../AuthProvider';
// import { DateTime } from "luxon";

// export function formatDate(date, format = 'dd.mm.yyyy') {
//   const replaces = {
//       yyyy: date.getFullYear(),
//       mm: ('0'+(date.getMonth() + 1)).slice(-2),
//       dd: ('0'+date.getDate()).slice(-2),
//       hh: ('0'+date.getHours()).slice(-2),
//       MM: ('0'+date.getMinutes()).slice(-2),
//       ss: ('0'+date.getSeconds()).slice(-2)
//   };
//   let result = format;
//   for(const replace in replaces){
//       result = result.replace(replace,replaces[replace]);
//   }
//   return result;
// };

export default class ContentTree extends Component {
  constructor(props) {
    super(props);

    this.textInput = [];

    this.needFocus = 0;
  
    this.setTextInputRef = element => {
      if (element) { 
        const id = element.getAttribute('data-id');
        this.textInput[id] = element;
      }
    };

    this.focusTextInput = (id) => {
      if (this.textInput[id] !== undefined) this.textInput[id].focus();
    };
  }

  componentDidUpdate() {
    if (this.needFocus > 0) {
      this.focusTextInput(this.needFocus);
      this.needFocus = 0;
    }
  }

  render() {
    const { treeData, treeState, selectedStoryId } = this.props;

    const getNodeKey = ({ node }) => node.id;

    const getMaxId = (nodes) => {
      let maxId = 0;
      if (Array.isArray(nodes)) {
        nodes.forEach((node) => {
          if (node.id > maxId) {
            maxId = node.id;
          }
          if (Array.isArray(node.children)) {
            let childMaxId = getMaxId(node.children);
  
            if (childMaxId > maxId) {
              maxId = childMaxId;
            }
          }
        });
      }
  
      return maxId;
    }
  
    if (treeState === "init" || treeState === "pending") {
      return (
        <div style={{ height: 'calc(100vh - 70px)', marginTop: 64 }}>
          <div style={{marginTop: 8, marginLeft:8}}>
            <CircularProgress size={20} />
          </div>
        </div>
      );
    }

    if (treeState === "error") {
      return (
        <div style={{ height: 'calc(100vh - 70px)', marginTop: 64 }}>
          Ошибка при загрузке данных... Обновите страницу.
        </div>
      );
    }

    if (treeState === "done") {
      return (
        <div style={{ height: 'calc(100vh - 70px)', marginTop: 64 }}>
          <SortableTree
            treeData={treeData}
            onChange={treeData => this.props.onChangeTree(treeData)}
            getNodeKey={getNodeKey}
            generateNodeProps={({ node, path }) => ({
              style: {
                boxShadow: selectedStoryId === node.id ? `0 0 0 4px cornflowerblue` : 'none'
              },
              title: (
                <input
                  style={{ fontSize: '1rem' }}
                  id={'input' + node.id}
                  data-id={node.id}
                  ref={this.setTextInputRef}
                  value={node.name}
                  onChange={event => {
                    const name = event.target.value;
                    this.props.onChangeTree(changeNodeAtPath({
                        treeData,
                        path,
                        getNodeKey,
                        newNode: { ...node, name },
                      }), node.id)
                  }}
                  onFocus={event => {
                    this.props.onSelectStory(node.id, path);
                  }}
                />
              ),
              buttons: [
                <IconButton style={{ padding: '2px' }}
                  onClick={() => {
                    const newNodeId = getMaxId(treeData) + 1;
                    this.props.onChangeTree(addNodeUnderParent({
                        treeData,
                        parentKey: path[path.length - 1],
                        expandParent: true,
                        getNodeKey,
                        newNode: {
                          id: newNodeId,
                          name: '',
                          draft: 1,
                          edit_now: 0,
                          public_allow: 0,
                          need_donat: 0,
                          // locked_user: getUserId(),
                          // date_create: formatDate(new Date()),
                          // date_update: formatDate(new Date())
                          // date_create: DateTime.local().toMillis(),
                          // date_update: DateTime.local().toMillis()
                        },
                      }).treeData, newNodeId)
                    //this.focusTextInput(newNodeId)  
                    this.needFocus = newNodeId
                  }}
                >
                  <AddIcon />
                </IconButton>,
                <IconButton style={{ padding: '2px', display: path.length === 1 ? 'none' : 'inline-flex' }}
                  onClick={() => {
                    this.focusTextInput(path[path.length - 2])
                    let newData = removeNode({treeData, path, getNodeKey});
                    this.props.onChangeTree(newData.treeData, node.id, path);
                    this.props.onClearChildren(newData.node);
                  }}
                >
                  <DelIcon />
                </IconButton>,
              ],
            })}
          />
        </div>
      );
    }
  }
}