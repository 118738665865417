import React from 'react';
import { hydrate, render } from "react-dom";
import './index.css';
import 'react-sortable-tree/style.css';
import 'm-react-splitters/lib/splitters.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// render.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) { // …Если в корневом элементе есть контент, то…
//  hydrate(<App />, rootElement); // …"цепляем" приложение на существующий DOM.
  rootElement.replaceChildren();
  render(<App />, rootElement);
} else { // …Иначе рендерим приложение стандартным образом
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
