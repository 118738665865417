import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LabelIcon from '@material-ui/icons/InsertDriveFileOutlined';
import FolderIcon from '@material-ui/icons/FolderOpenOutlined';
import LabelIconMarked from '@material-ui/icons/DescriptionOutlined';
import EditIcon from '@material-ui/icons/CreateOutlined';
import { ReactComponent as MainIcon } from '../icons/main.svg';
import { ReactComponent as Icon1 } from '../icons/1.svg'
import { ReactComponent as Icon2 } from '../icons/2.svg'
import { ReactComponent as Icon3 } from '../icons/3.svg'
import { ReactComponent as Icon4 } from '../icons/4.svg'
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { observer } from "mobx-react-lite";
import { useMst } from "../models/Root";
import { useAuth, getRole } from '../components/AuthProvider';

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
//      color: theme.palette.text.secondary,
//      color: theme.palette.text.primary,
      color: 'var(--tree-view-color)',
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[300]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
//      color: theme.palette.text.primary,
//      color: theme.palette.text.secondary,
      color: 'var(--tree-view-color)',

//   borderTopRightRadius: theme.spacing(2),
    //   borderBottomRightRadius: theme.spacing(2),
    //   paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        // fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
    //   marginLeft: 0,
      '& $content': {
        // paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(0.5),
    },
    dopIcon: {
      marginRight: theme.spacing(0.1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      
    },
  }));
  
function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, dopIcon: DopIcon, ...other } = props;

  return (
    <TreeItem
      label={
        labelText !== "Загрузка..." ?
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          {labelInfo !== null ? 
           <DopIcon color="inherit" className={classes.dopIcon} fontSize="small"/>
          : ""} 
        </div> :
        <CircularProgress size={20} style={{marginTop: 4}} />
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  dopIcon: PropTypes.elementType,
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
      },
}));

const ContentTree = observer(() => {
  const classes = useStyles();
  const [selected, setSelected] = useState(0);
  const [expanded, setExpanded] = useState([]);
  const { book, tree, mobile } = useMst();
  const [logged] = useAuth();

  const handleToggle = (event, nodeIds) => {
    tree.setToggle(true);
    if (book.state === "done") {
      if (nodeIds.length > expanded.length) {
        setExpanded(nodeIds);
        book.loadChildren(Number(nodeIds[0]), false);
      } else {
        setExpanded(nodeIds);
      }
      tree.setExpanded(nodeIds);
    }
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    book.selectStory(Number(nodeIds));
    tree.setSelected(Number(nodeIds));

    if (logged) {
      let role = getRole();
      if (role === 'AUTHOR' || role === 'ADMIN' || role === 'BETA_READER') {
        book.setReaderLink('/reader/' + book.selectedStory.id);
      } else {
        book.setReaderLink('/story/' + book.selectedStory.public_id);
      }
    } else {
      book.setReaderLink('/story/' + book.selectedStory.public_id);
    }
    book.setSharedLink('/story/' + book.selectedStory.public_id);
    
    book.setCommentAnswering(-Math.floor(Math.random() * 1000000));

    if (!tree.toggle && mobile.open) {
      mobile.toggle()
    }
    tree.setToggle(false);
  };

  useEffect(() => {
    if (book.state === "init") {
      book.loadStories();
    }
    if (book.state === "done" && tree.selected === 0 && tree.expanded.length === 0) {
      tree.setExpanded(['55']);
      tree.setSelected(55);
    }
    setExpanded(tree.expanded);
    if (selected != tree.selected) {
      setSelected(tree.selected);
      book.selectStory(tree.selected);
    }
  }, [book, book.state, tree.expanded, tree.selected]);

const renderTree = (nodes) => {
  let Icon = nodes.marked > 0 ? LabelIconMarked : LabelIcon;
  if (Array.isArray(nodes.children) && nodes.children.length > 0) {
    Icon = FolderIcon;
  }
  switch (nodes.id) {
    case 55: 
      Icon = MainIcon;
      break;
    case 56: 
      Icon = Icon1;
      break;
    case 53: 
      Icon = Icon2;
      break;
    case 54: 
      Icon = Icon3;
      break;
    case 111: 
      Icon = Icon4;
      break;
  }
  const labelInfo = nodes.edit_now !== 0 ? "" : null;
  const DopIcon = nodes.edit_now !== 0 ? EditIcon : null;
  const textColor = nodes.draft === 0 ? 'black' : 'gray';

  return (
    <StyledTreeItem key={String(nodes.id)} nodeId={String(nodes.id)} color={textColor} labelText={nodes.name} labelIcon={Icon} labelInfo={labelInfo} dopIcon={DopIcon}>
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </StyledTreeItem >
  )
};

  return (
    <div>
    {/* <div hidden={book.state === "done"} style={{marginTop: 8}}>
      <CircularProgress size={20} />
    </div> */}
    <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={['root']}
        defaultExpandIcon={<ChevronRightIcon />}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        expanded={expanded}
        selected={[String(selected)]}
        // defaultEndIcon={<LabelIcon />}
    >
        {book.state === "done" ? renderTree(book.stories[0]) : ""}
    </TreeView>
    </div>
  );
});

export default ContentTree;