import React, { useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { login, getIP } from './AuthProvider';
import { observer } from "mobx-react-lite";
import { useMst } from "../models/Root";
import { maxPasswordLength, maxEmailLength } from '../utils/constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Поле email обязательно для заполнения').email('Укажите корректый e-mail'),
  password: Yup.string().required('Поле пароль обязательно для заполнения').max(30, 'Пароль может содержать максимум 30 символов')
  .min(4, 'Пароль должен содержать минимум 4 символа')
  .matches(/^[a-zA-Zа-яА-ЯёЁ0-9_\-+=!@#$%^&*()]{3,30}$/, 'Допускаются только буквы, цифры и символы: _-+=!@#$%^&*()'),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  topBar: {
    display: 'flex',
    alignItems: 'right',
  },
  errorMessage: {
    color: 'red',
    fontSize: '12px',
    marginTop: '-6px',
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SignIn = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [alert_message, setAlertMessage] = useState('Попытка входа не удалась. Проверьте правильность заполнения полей email и пароль.');
  let location = useLocation();
  const { user } = useMst();
  let { from } = location.state || { from: { pathname: "/" } };
  const initialValues = {
    email: '',
    password: '',
    user_agent: navigator.userAgent,
    ip: '',
    remember: true,
  };

  const onSubmit = (values) => {
    user.updateEmail(values.email);
    const getIp = getIP();

    getIp.then(ip => {
        let data = values;
        data.ip = ip.ip;

        fetch(process.env.REACT_APP_API_URL + '/users/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            credentials: 'include',
            body: JSON.stringify(data)
        })
          .then(r => r.json())
          .then(r => {
            if ('success' === r.status) {
              login(r.message);
              history.replace(from);
            } else {
              if (r.message === 'inactive') {
                history.push("/account-creating");
              } else {
                setAlertMessage('Попытка входа не удалась. Проверьте правильность заполнения полей email и пароль.');
                setOpen(true);  
              }
            }
          })
          .catch(err => {
            setAlertMessage('Попытка входа не удалась. Проверьте правильность заполнения полей email и пароль.');
            setOpen(true);
          })
    }).catch(err => {
        console.error(err);

        fetch(process.env.REACT_APP_API_URL + '/users/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          credentials: 'include',
          body: JSON.stringify(values)
      })
        .then(r => r.json())
        .then(r => {
          if ('success' === r.status) {
            login(r.message);
            history.replace(from);
          } else {
            if (r.message === 'inactive') {
              history.push("/account-creating");
            } else {
              setAlertMessage('Попытка входа не удалась. Проверьте правильность заполнения полей email и пароль.');
              setOpen(true);  
            }
          }
        })
        .catch(err => {
          setAlertMessage('Попытка входа не удалась. Проверьте правильность заполнения полей email и пароль.');
          setOpen(true);
        })
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // const onChange = ({target: {name, value}}) => {
  //   if (name === 'remember') {
  //     value = !credentials.remember;
  //   }
  //   setCredentials({...credentials, [name]: value})
  // };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Авторизация
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}/>
            {formik.touched.email && formik.errors.email ? (
              <Typography className={classes.errorMessage}>{formik.errors.email}</Typography>
            ) : null}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoComplete="password"
            name="password"
            label="Пароль"
            type="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
            {formik.touched.password && formik.errors.password ? (
              <Typography className={classes.errorMessage}>{formik.errors.password}</Typography>
            ) : null}
          <FormControlLabel
            control={<Checkbox id="remember" name="remember" color="primary" checked={formik.values.remember} onChange={formik.handleChange} onBlur={formik.handleBlur}/>}
            label="Запомнить меня"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Вход
          </Button>
          <Grid container>
            <Grid item sm xs={12}>
              {/* <Link href="#" variant="body2">
                Забыли пароль?
              </Link> */}
            </Grid>
            <Grid item>
              <Link to="/sign-up" component={RouterLink} variant="body2">
                {"Ещё нет аккаунта? Зарегистрироваться"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {alert_message}
        </Alert>
      </Snackbar>
    </Container>
  );
});

// Вернуть ссылку на место. Установить disabled в false в SignUp.js и AccountCreating.js
{/* <Link to="/sign-up" component={RouterLink} variant="body2"> */}

export default SignIn;