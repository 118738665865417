import React, { Component } from 'react';
import Navbar from '../components/editor/Navbar';
import Mainbar from '../components/editor/Mainbar';
import ContentTree from '../components/editor/ContentTree';
import Splitter from 'm-react-splitters';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { authFetch, getUserId } from '../components/AuthProvider';
import IndexedDbRepository, { ANNOTATION_STORE_NAME, TEXT_STORE_NAME, TOOLTIP_STORE_NAME } from '../components/IndexedDbRepository';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { walk, getNodeAtPath, changeNodeAtPath } from 'react-sortable-tree';
import { generateUUID } from '../utils/utils';

function deleteUselessSpan(match, beginSpan, textSpan, endSpan) {
  return textSpan;
}

function prepareForSave(nodes, annotations, texts, tooltips, id_parent, result) {
  for (let i = 0; i < nodes.length; i++) {
    let node = {};
    node.id = nodes[i].id;
    node.id_parent = id_parent;
    node.name = nodes[i].name;
    node.position = i + 1;
    node.marked = nodes[i].marked !== undefined ? nodes[i].marked : 0;
    node.edit_status = nodes[i].edit_status !== undefined ? nodes[i].edit_status : 0;
    node.edit_now = nodes[i].edit_now !== undefined ? nodes[i].edit_now : 0;
    node.draft = nodes[i].draft !== undefined ? nodes[i].draft : 0;
    node.need_donat = nodes[i].need_donat !== undefined ? nodes[i].need_donat : 0;
    node.public_allow = nodes[i].public_allow !== undefined ? nodes[i].public_allow : 0;
    if (nodes[i].public_id !== undefined && nodes[i].public_id !== '') {
      node.public_id = nodes[i].public_id;
    } else {
      node.public_id = node.public_allow === 0 ? '' : generateUUID();
    }
    if (annotations[nodes[i].id] !== undefined && annotations[nodes[i].id].changed) {
      node.annotation = annotations[nodes[i].id].text;
    }
    if (texts[nodes[i].id] !== undefined && texts[nodes[i].id].changed) {
      node.text = texts[nodes[i].id].text;

      const newLine = /\n+/g;
      node.text = node.text.replace(/(<span id=".{36}">)(.*?)(<\/span>)/gus, deleteUselessSpan).replace(newLine, '');

      if (node.text.includes('<span id=') || node.text.includes('<span style="background-color:"')) {
        node.marked = 1;
      } else {
        node.marked = 0;
      }

      if (tooltips[nodes[i].id] !== undefined && Array.isArray(tooltips[nodes[i].id])) {
        tooltips[nodes[i].id].forEach((tooltip) => {
          if (!node.text.includes(tooltip.id_tooltip)) {
            tooltip.changed = true;
            tooltip.deleted = true;
          }

          if (tooltip.changed) {
            if (node.tooltips === undefined) {
              node.tooltips = [];
            }
            node.tooltips.push(tooltip);
          }
        })
      }
    }
    result.push(node);
  }

  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].children !== undefined && nodes[i].children.length > 0) {
        prepareForSave(nodes[i].children, annotations, texts, tooltips, nodes[i].id, result)
    }    
  }
}

function getNodeKey({ node }) {
    return node.id;
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeState: "init",
      treeData: [],
      annotations: [],
      texts: [],
      notes: [],
      tooltips: [],
      selectedStoryId: 0,
      selectedStoryPath: [],
      message: "Изменения успешно сохранены на сервере.",
      openMessage: false,
      messageType: "success",
      openDialog: false,
      editorFullscreen: false,
    };

    this.onChangeTree = this.onChangeTree.bind(this);
    this.onSelectStory = this.onSelectStory.bind(this);
    this.onClearChildren = this.onClearChildren.bind(this);
    this.onAnnotaionChange = this.onAnnotaionChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onAddTooltip = this.onAddTooltip.bind(this);
    this.onDeleteTooltip = this.onDeleteTooltip.bind(this);
    this.onEditNowChange = this.onEditNowChange.bind(this);
    this.onFullscreen = this.onFullscreen.bind(this);
    this.onDraftChange = this.onDraftChange.bind(this);
    this.onPublicAllowChange = this.onPublicAllowChange.bind(this);
    this.onNeedDonatChange = this.onNeedDonatChange.bind(this);
    this.onEditStatusChange = this.onEditStatusChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onDiscardClick = this.onDiscardClick.bind(this);
    // this.changeTree = this.changeTree.bind(this);
    // this.lock = this.lock.bind(this);
    this.handleCloseMessage = this.handleCloseMessage.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.loadStories = this.loadStories.bind(this);
    this.handleDeclineDialog = this.handleDeclineDialog.bind(this);
    this.handleAcceptDialog = this.handleAcceptDialog.bind(this);

    this.repository = new IndexedDbRepository( 'id' );
    this.handleUpdateStory = async( objectName, id, newElement ) => {
      await this.repository.save( objectName, { id, text: newElement } );
    };
    this.handleFindStory = async( objectName, id ) => {
        return await this.repository.FindById( objectName, id );
      };
    this.handleDeleteStory = async( objectName, id ) => {
      await this.repository.deleteById( objectName, id );
    };
    this.handleClear = async( objectName ) => {
        await this.repository.clear( objectName );
    };
    this.handleFindAll = async( objectName ) => {
        return await this.repository.findAll( objectName );
    };
  }

  onChangeTree(treeData, changeNodeId, path) {
    if (changeNodeId !== undefined && this.state.annotations[changeNodeId] === undefined) {
      let newAnnotations = this.state.annotations;
      let newTexts = this.state.texts;
      let newTooltips = this.state.tooltips;
      newAnnotations[changeNodeId] = {text: "", changed: false};
      newTexts[changeNodeId] = {text: "", changed: false};
      newTooltips[changeNodeId] = [];
      this.setState(state => ({treeData: treeData,
                               annotations: newAnnotations,
                               texts: newTexts,
                               tooltips: newTooltips}));
      this.handleUpdateStory(ANNOTATION_STORE_NAME, changeNodeId, "");
      this.handleUpdateStory(TEXT_STORE_NAME, changeNodeId, "");
      this.handleUpdateStory(TOOLTIP_STORE_NAME, changeNodeId, []);
    } else {
      if (path !== undefined) {
        this.handleDeleteStory(TEXT_STORE_NAME, changeNodeId);
        this.handleDeleteStory(ANNOTATION_STORE_NAME, changeNodeId);
        this.handleDeleteStory(TOOLTIP_STORE_NAME, changeNodeId);
        let newAnnotations = this.state.annotations;
        let newTexts = this.state.texts;
        let newTooltips = this.state.tooltips;
        newAnnotations[changeNodeId] = undefined;
        newTexts[changeNodeId] = undefined;
        newTooltips[changeNodeId] = undefined;
        this.setState(state => ({treeData: treeData,
                                 annotations: newAnnotations,
                                 texts: newTexts,
                                 tooltips: newTooltips,
                                 selectedStoryId: path[path.length - 2],
                                 selectedStoryPath: path.slice(0, path.length - 1)}));
      } else {  
        this.setState(state => ({treeData: treeData}));
      }
    }
    
    if (changeNodeId !== undefined) {
      localStorage.setItem('GYOSS_STORIES_TREE', JSON.stringify(treeData));
    }
  }

  onClearChildren(node) {
    let newAnnotations = this.state.annotations;
    let newTexts = this.state.texts;
    let newTooltips = this.state.tooltips;
    
    walk({treeData: node.children, getNodeKey, callback: (nodeInfo) => {
        this.handleDeleteStory(TEXT_STORE_NAME, nodeInfo.node.id);
        this.handleDeleteStory(ANNOTATION_STORE_NAME, nodeInfo.node.id);
        this.handleDeleteStory(TOOLTIP_STORE_NAME, nodeInfo.node.id);
        newAnnotations[nodeInfo.node.id] = undefined;
        newTexts[nodeInfo.node.id] = undefined;
        newTooltips[nodeInfo.node.id] = undefined;
    }});
    this.setState(state => ({annotations: newAnnotations, texts: newTexts, tooltips: newTooltips}));
  }
  
  onSelectStory(id, path) {
    if (this.state.annotations[id] === undefined || this.state.texts[id] === undefined) {
      authFetch(process.env.REACT_APP_API_URL + '/stories/' + id + '/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      })
        .then(r => r.json())
        .then(r => {
          if ('success' === r.status) {
            let newAnnotations = this.state.annotations;
            let newTexts = this.state.texts;
            let newNotes = this.state.notes;
            let newTooltips = this.state.tooltips;
            if (this.state.annotations[id] === undefined) {
                newAnnotations[id] = {text: r.message.annotation, changed: false};
            }
            if (this.state.texts[id] === undefined) {
                newTexts[id] = {text: r.message.text, changed: false};
            }
            if (this.state.notes[id] === undefined) {
              newNotes[id] = r.message.mark_notes;
            }
            if (this.state.tooltips[id] === undefined) {
              newTooltips[id] = r.message.tooltips;
            }
            this.setState(state => ({annotations: newAnnotations, texts: newTexts, notes: newNotes, tooltips: newTooltips, selectedStoryId: id, selectedStoryPath: path}));
          } else {
            console.error("Failed to load story ", r)
          }
        })
        .catch(err => {
          console.error("Failed to load story ", err)
        })
    } else {
      this.setState(state => ({selectedStoryId: id, selectedStoryPath: path}));
    }
 }

  onAnnotaionChange(content, editor) {
    if (this.state.selectedStoryId !== 0) {
      if (this.state.annotations[this.state.selectedStoryId] !== undefined && this.state.annotations[this.state.selectedStoryId].text !== content) {
        let newAnnotations = this.state.annotations;
        newAnnotations[this.state.selectedStoryId] = {text: content, changed: true};
        this.setState(state => ({annotations: newAnnotations}));
        this.handleUpdateStory(ANNOTATION_STORE_NAME, this.state.selectedStoryId, content);
      }
    }
  }

  onAddTooltip(tooltipProps) {
    if (this.state.selectedStoryId !== 0) {
      let newTooltips = this.state.tooltips;
      let tooltipExists = false;
      newTooltips[this.state.selectedStoryId].forEach(newTooltip => {
        if (newTooltip.id_tooltip == tooltipProps.id_tooltip) {
          newTooltip.text = tooltipProps.text;
          newTooltip.changed = true;
          tooltipExists = true;
        }
      });
      if (tooltipExists === false) {
        let newTooltip = {
          id_story: this.state.selectedStoryId,
          id_tooltip: tooltipProps.id_tooltip,
          text: tooltipProps.text,
          changed: true
        };
        newTooltips[this.state.selectedStoryId].push(newTooltip);
      };
      
      let newTexts = this.state.texts;
      newTexts[this.state.selectedStoryId].changed = true;
      this.setState(state => ({texts: newTexts, tooltips: newTooltips}));
      this.handleUpdateStory(TEXT_STORE_NAME, this.state.selectedStoryId, newTexts[this.state.selectedStoryId].text);
      this.handleUpdateStory(TOOLTIP_STORE_NAME, this.state.selectedStoryId, newTooltips[this.state.selectedStoryId]);
    }
  }

  onDeleteTooltip(tooltipId) {
    if (this.state.selectedStoryId !== 0) {
      let newTooltips = this.state.tooltips;
      newTooltips[this.state.selectedStoryId].forEach(newTooltip => {
        if (newTooltip.id_tooltip == tooltipId) {
          newTooltip.changed = true;
          newTooltip.deleted = true;
          let newTexts = this.state.texts;
          newTexts[this.state.selectedStoryId].changed = true;
          this.setState(state => ({texts: newTexts, tooltips: newTooltips}));
          this.handleUpdateStory(TOOLTIP_STORE_NAME, this.state.selectedStoryId, newTooltips[this.state.selectedStoryId]);
        }
      });
    }
  }

  onTextChange(content, editor) {
    if (this.state.selectedStoryId !== 0) {
      const newLine = /\n+/g;
      // console.log(this.state.texts[this.state.selectedStoryId].text.replace(newLine, ''));
      // console.log(content.replace(newLine, ''));
      // console.log(this.state.texts[this.state.selectedStoryId].text.replace(newLine, '') == content.replace(newLine, ''));
      
      // const temp = this.state.texts[this.state.selectedStoryId].text.replace(newLine, '');
      // const temp1 = content.replace(newLine, '');
      // for (let i = 0; i < temp.length - 1; i++) {
      //   if (temp[i] != temp1[i])
      //     console.log(i, ' ', temp[i], ' ', temp1[i]);
      // }

      if (this.state.texts[this.state.selectedStoryId] !== undefined && this.state.texts[this.state.selectedStoryId].text.replace(newLine, '') !== content.replace(newLine, '')) {
        let newTexts = this.state.texts;
        newTexts[this.state.selectedStoryId] = {text: content, changed: true};

        let newTooltips = this.state.tooltips;
        newTooltips[this.state.selectedStoryId].forEach((tooltip) => {
          tooltip.changed = true;
        });
        this.setState(state => ({texts: newTexts, tooltips: newTooltips}));
        this.handleUpdateStory(TEXT_STORE_NAME, this.state.selectedStoryId, content);
        this.handleUpdateStory(TOOLTIP_STORE_NAME, this.state.selectedStoryId, newTooltips[this.state.selectedStoryId]);
  
      }
    }
  }

  onEditNowChange(event) {
    let newNode = getNodeAtPath({treeData: this.state.treeData, path: this.state.selectedStoryPath, getNodeKey}).node;
    newNode.edit_now = event.target.checked ? 1 : 0;

    const treeData = changeNodeAtPath({treeData: this.state.treeData, path:  this.state.selectedStoryPath, newNode, getNodeKey});
    this.setState(state => ({treeData}));
    localStorage.setItem('GYOSS_STORIES_TREE', JSON.stringify(treeData));
  }

  onFullscreen() {
    const editorFullscreen = this.state.editorFullscreen;
    this.setState(state => ({editorFullscreen: !editorFullscreen}));
  }
  
  onDraftChange(event) {
    let newNode = getNodeAtPath({treeData: this.state.treeData, path: this.state.selectedStoryPath, getNodeKey}).node;
    newNode.draft = event.target.checked ? 1 : 0;

    if (newNode.draft === 0) {
      newNode.public_allow = 1;
      if (newNode.public_id === undefined || newNode.public_id === '') {
        newNode.public_id = generateUUID();
      }  
    }

    const treeData = changeNodeAtPath({treeData: this.state.treeData, path:  this.state.selectedStoryPath, newNode, getNodeKey});
    this.setState(state => ({treeData}));
    localStorage.setItem('GYOSS_STORIES_TREE', JSON.stringify(treeData));
  }

  onPublicAllowChange(event) {
    let newNode = getNodeAtPath({treeData: this.state.treeData, path: this.state.selectedStoryPath, getNodeKey}).node;
    
    if (newNode.draft === 0 && !event.target.checked) {
      return;
    }

    newNode.public_allow = event.target.checked ? 1 : 0;

    if (newNode.public_allow === 1) {
      if (newNode.public_id === undefined || newNode.public_id === '') {
        newNode.public_id = generateUUID();
      }
    }

    const treeData = changeNodeAtPath({treeData: this.state.treeData, path:  this.state.selectedStoryPath, newNode, getNodeKey});
    this.setState(state => ({treeData}));
    localStorage.setItem('GYOSS_STORIES_TREE', JSON.stringify(treeData));
  }

  onNeedDonatChange(event) {
    let newNode = getNodeAtPath({treeData: this.state.treeData, path: this.state.selectedStoryPath, getNodeKey}).node;
    newNode.need_donat = event.target.checked ? 1 : 0;

    const treeData = changeNodeAtPath({treeData: this.state.treeData, path:  this.state.selectedStoryPath, newNode, getNodeKey});
    this.setState(state => ({treeData}));
    localStorage.setItem('GYOSS_STORIES_TREE', JSON.stringify(treeData));
  }

  onEditStatusChange(event) {
    // const userId = getUserId();
    // let newNode = getNodeAtPath({treeData: this.state.treeData, path: this.state.selectedStoryPath, getNodeKey}).node;
    
    // if (newNode.locked_user == undefined || newNode.locked_user == 0) {
    //   authFetch(process.env.REACT_APP_API_URL + '/stories/' + id_story + '/lock', {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json;charset=utf-8'
    //     }
    //   })
    //     .then(r => r.json())
    //     .then(r => {
    //       if ('success' === r.status) {
    //         newNode.locked_user = r.message;
    //         if (newNode.locked_user == userId) {
    //           newNode.edit_status = event.target.value;
    //           this.changeTree(newNode);
    //         }
    //       } else {
    //         console.error("Failed to check lock book ", r)
    //         this.setState(state => ({ message: "Ошибка при проверке блокировки истории. Попробуйте обновить страницу.", openMessage: true, messageType: "error" }));
    //       }
    //     })
    //     .catch(err => {
    //       console.error("Failed to check lock book ", err)
    //       this.setState(state => ({ message: "Ошибка при проверке блокировки истории. Попробуйте обновить страницу.", openMessage: true, messageType: "error" }));
    //     })
    // }

    // if (newNode.locked_user == undefined || newNode.locked_user == 0 || newNode.locked_user == userId) {
    //   newNode.edit_status = event.target.value;
      
    //   if (newNode.locked_user != userId) {
    //     newNode.locked_user = userId;
    //     this.lock(newNode.id, userId);
    //   }
  
    //   const treeData = changeNodeAtPath({treeData: this.state.treeData, path:  this.state.selectedStoryPath, newNode, getNodeKey});
    //   this.setState(state => ({treeData}));
    //   localStorage.setItem('GYOSS_STORIES_TREE', JSON.stringify(treeData));
    // }
    let newNode = getNodeAtPath({treeData: this.state.treeData, path: this.state.selectedStoryPath, getNodeKey}).node;
    newNode.edit_status = event.target.value;

    const treeData = changeNodeAtPath({treeData: this.state.treeData, path:  this.state.selectedStoryPath, newNode, getNodeKey});
    this.setState(state => ({treeData}));
    localStorage.setItem('GYOSS_STORIES_TREE', JSON.stringify(treeData));
  }

  // changeTree(newNode) {
  //   const treeData = changeNodeAtPath({treeData: this.state.treeData, path: this.state.selectedStoryPath, newNode, getNodeKey});
  //   this.setState(state => ({treeData}));
  //   localStorage.setItem('GYOSS_STORIES_TREE', JSON.stringify(treeData));
  // }

  // lock(id_story, id_user) {
  //   authFetch(process.env.REACT_APP_API_URL + '/stories/' + id_story + '/lock/' + id_user, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json;charset=utf-8'
  //     }
  //   })
  //     .then(r => r.json())
  //     .then(r => {
  //       if ('success' === r.status) {
  //       } else {
  //         console.error("Failed to lock book ", r)
  //         this.setState(state => ({ message: "Ошибка при блокировке истории на сервере. Попробуйте обновить страницу.", openMessage: true, messageType: "error" }));
  //       }
  //     })
  //     .catch(err => {
  //       console.error("Failed to lock book ", err)
  //       this.setState(state => ({ message: "Ошибка при блокировке истории на сервере. Попробуйте обновить страницу.", openMessage: true, messageType: "error" }));
  //     })
  // }

  discardChanges() {
    localStorage.removeItem('GYOSS_STORIES_TREE');
    this.handleClear(ANNOTATION_STORE_NAME);
    this.handleClear(TEXT_STORE_NAME);
    this.handleClear(TOOLTIP_STORE_NAME);
    let annotations = this.state.annotations;
    let texts = this.state.texts;
    let tooltips = this.state.tooltips;
    annotations.forEach((item) => {
      if (item != undefined) {
        item.changed = false;
      }
    });
    texts.forEach((item) => {
      if (item != undefined) {
        item.changed = false;
      }
    });
    let newTooltips = tooltips.map((item) => {
      if (item != undefined) {
        let newItem = item.map((tooltip) => {
          if (!tooltip.hasOwnProperty('deleted') || tooltip.deleted === false) {
            tooltip.changed = false;
            return tooltip;
          }
        }).filter((tooltip) => tooltip !== undefined);
        return newItem;
      } else {
        return undefined;
      }
    });
    this.setState(state => ({texts, annotations, tooltips: newTooltips}));
  }

  onSaveClick() {
    let dataForSave = [];
    prepareForSave(this.state.treeData, this.state.annotations, this.state.texts, this.state.tooltips, 0, dataForSave);
    // console.log(JSON.stringify(dataForSave));
    authFetch(process.env.REACT_APP_API_URL + '/stories', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(dataForSave)
    })
      .then(r => r.json())
      .then(r => {
        if ('success' === r.status) {
          this.discardChanges();
          this.setState(state => ({ message: "Изменения успешно сохранены на сервере.", openMessage: true, messageType: "success" }));
        } else {
          console.error("Failed to save book ", r)
          this.setState(state => ({ message: "Ошибка при отправке данных на сервер. Попробуйте сохранить ещё раз.", openMessage: true, messageType: "error" }));
        }
      })
      .catch(err => {
        console.error("Failed to save book ", err)
        this.setState(state => ({ message: "Ошибка при отправке данных на сервер. Попробуйте сохранить ещё раз.", openMessage: true, messageType: "error" }));
      })
  }

  onDiscardClick() {
    this.discardChanges();
    this.loadStories();    
    this.setState(state => ({ message: "Все изменения успешно удалены.", openMessage: true }));
  }

  loadStories() {
    this.setState(state => ({ treeState: "pending" }));

    fetch(process.env.REACT_APP_API_URL + '/stories', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
      .then(r => r.json())
      .then(r => {
        if ('success' === r.status) {
          this.setState(state => ({ treeData: r.message, treeState: "done" }));
          if (r.message.length > 0) {
            this.onSelectStory(r.message[0].id, [r.message[0].id]);
          }
        } else {
          console.error("Failed to load books ", r)
          this.setState(state => ({ treeState: "error" }));
        }
      })
      .catch(err => {
        console.error("Failed to load books ", err)
        this.setState(state => ({ treeState: "error" }));
      })
  }

  componentDidMount(){
    this.handleFindAll(ANNOTATION_STORE_NAME)
        .then(localAnnotations => {
            this.handleFindAll(TEXT_STORE_NAME)
            .then(localTexts => {
                this.handleFindAll(TOOLTIP_STORE_NAME)
                .then(localTooltips => {
                    if ((localStorage.getItem('GYOSS_STORIES_TREE') !== undefined && localStorage.getItem('GYOSS_STORIES_TREE') !== null) || localTexts.length > 0 || localAnnotations.length > 0 || localTooltips.length > 0) {
                        this.setState(state => ({ openDialog: true }));
                    } else {
                        this.loadStories();
                    }
                })
                .catch(err => {
                    console.error("Failed to load local texts ", err)
                })
            })
            .catch(err => {
                console.error("Failed to load local texts ", err)
            })
        })
        .catch(err => {
            console.error("Failed to load local annotations ", err) 
        })
  }

  handleCloseMessage(event, reason) {
    if (reason === 'clickaway') {
        return;
    }
  
    this.setState(state => ({ openMessage: false }));
  }

  handleDeclineDialog() {
    this.discardChanges();
    this.setState(state => ({ openDialog: false }));
    this.loadStories();
  }

  handleAcceptDialog() {
    this.handleFindAll(ANNOTATION_STORE_NAME)
        .then(localAnnotations => {
            this.handleFindAll(TEXT_STORE_NAME)
            .then(localTexts => {
                this.handleFindAll(TOOLTIP_STORE_NAME)
                .then(localTooltips => {
                    let newAnnotations = [];
                    let newTexts = [];
                    let newTooltips = [];
                    localAnnotations.forEach((item) => newAnnotations[item.id] = {text: item.text, changed: true});
                    localTexts.forEach((item) => newTexts[item.id] = {text: item.text, changed: true});
                    localTooltips.forEach((item) => {
                      newTooltips[item.id] = item.text;
                      newTooltips[item.id].forEach((tooltip) => tooltip.changed = true) 
                    });

                    this.setState(state => ({ annotations: newAnnotations, texts: newTexts, tooltips: newTooltips }));
                    
                    let localTree = localStorage.getItem('GYOSS_STORIES_TREE');
                    if (localTree !== undefined && localTree !== null) {
                        localTree = JSON.parse(localTree);
                        this.setState(state => ({ treeData: localTree, treeState: "done" }));
                        this.onSelectStory(localTree[0].id, [localTree[0].id]);
                    } else {
                        this.loadStories();
                    }
                    this.setState(state => ({ openDialog: false }));
                })
                .catch(err => {
                    console.error("Failed to load local texts ", err)
                })
            })
            .catch(err => {
                console.error("Failed to load local texts ", err)
            })
        })
        .catch(err => {
            console.error("Failed to load local annotations ", err) 
        })
  }

  render() {
    const selectedNode = getNodeAtPath({treeData: this.state.treeData, path: this.state.selectedStoryPath, getNodeKey}).node;

    return (
      <div style={{ display: 'flex', backgroundColor: '#F4F6F6', height: '100vh'}}>
        <Navbar 
          onSaveClick = {this.onSaveClick}
          onDiscardClick = {this.onDiscardClick}
        />
        <Splitter
          position="vertical"
          primaryPaneMaxWidth="90%"
          primaryPaneMinWidth={0}
          primaryPaneWidth="40%"
          minimalizedPrimaryPane={this.state.editorFullscreen}
          postPoned={false}
        >  
          <ContentTree
            selectedStoryId = {this.state.selectedStoryId}
            treeData={this.state.treeData} 
            treeState={this.state.treeState} 
            onChangeTree={this.onChangeTree}
            onSelectStory={this.onSelectStory}
            onClearChildren={this.onClearChildren}
          />
          <Mainbar
            id_story={this.state.selectedStoryId}
            annotation={this.state.annotations[this.state.selectedStoryId] !== undefined ? this.state.annotations[this.state.selectedStoryId].text : ""}
            text={this.state.texts[this.state.selectedStoryId] !== undefined ? this.state.texts[this.state.selectedStoryId].text : ""}
            notes={this.state.notes[this.state.selectedStoryId] !== undefined ? this.state.notes[this.state.selectedStoryId] : []}
            tooltips={this.state.tooltips[this.state.selectedStoryId] !== undefined ? this.state.tooltips[this.state.selectedStoryId] : []}
            edit_status={selectedNode.edit_status}
            edit_now={selectedNode.edit_now !== 0 ? true : false}
            draft={selectedNode.draft !== 0 ? true : false}
            public_allow={selectedNode.public_allow !== 0 ? true : false}
            public_id={selectedNode.public_id}
            need_donat={selectedNode.need_donat !== 0 ? true : false}
            treeData={this.state.treeData}
            onAnnotaionChange={this.onAnnotaionChange}
            onTextChange={this.onTextChange}
            onEditNowChange={this.onEditNowChange}
            onDraftChange={this.onDraftChange}
            onPublicAllowChange={this.onPublicAllowChange}
            onNeedDonatChange={this.onNeedDonatChange}
            onEditStatusChange={this.onEditStatusChange}
            onFullscreen={this.onFullscreen}
            onAddTooltip={this.onAddTooltip}
            onDeleteTooltip={this.onDeleteTooltip}
          />
        </Splitter>
        <Snackbar open={this.state.openMessage} autoHideDuration={7000} onClose={this.handleCloseMessage}>
        <Alert onClose={this.handleCloseMessage} severity={this.state.messageType}>
          {this.state.message}
        </Alert>
        </Snackbar>
        <Dialog
            open={this.state.openDialog}
            onClose={this.handleDeclineDialog}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle id="draggable-dialog-title">
                Несохраненные изменения
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Внимание. Обнаружены несохраненные локальные изменения с прошлого сеанса. Желаете загрузить их? В случае отказа они будут удалены.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus disableFocusRipple={true} onClick={this.handleAcceptDialog} color="primary">
                    Загрузить
                </Button>
                <Button autoFocus disableFocusRipple={true} onClick={this.handleDeclineDialog} color="primary">
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
      </div>
    );
  }
}