import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { observer } from "mobx-react-lite";
import { useMst } from "../../models/Root";
import { getUserId, getUserName } from '../../components/AuthProvider';

const useStyles = makeStyles((theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    minWidth: 300,
    maxHeight: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  cardContent: {
    padding: '10px',
    '&:last-child': {
      paddingBottom: '10px',
    }
  },
  commentHeder: {
    marginBlockStart: 0,
    marginBlockEnd: '0.5em',
  },
  commentTextArea: {
    width: '100%',
    marginBlockStart: '0.5rem',
    '& textarea': {
      fontSize: theme.typography.body2.fontSize,
    }
  },
  commentSendButton: {
    marginBlockStart: '0.5rem',
    marginRight: '0.5rem',
  },
  list: {
    width: '100%',
    maxWidth: '375px',
    maxHeight: '200px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  author: {
    display: 'block',
    textAlign: 'right',
  },
  listItemText: {
    whiteSpace: 'pre-wrap',
  },
}));

const MarkNotes = observer((props) => {
  const classes = useStyles();
  const { book } = useMst();
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setValue('');
    props.onClose();
  };

  const onAddNote = () => {
    let note = {
      id_mark: props.markId,
      id_user: getUserId(),
      user_name: getUserName(),
      text: value,
    }
    book.currentReadingAddNote(note);
    setValue('');
  }

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="dic-modal-title"
      aria-describedby="dic-modal-list"
    >
      <Card className={classes.paper} variant="outlined">
        <CardContent className={classes.cardContent}>
          <h4 id="dic-modal-title" className={classes.commentHeder}>
            Комментарии к выделенному блоку
          </h4>
          <Divider />
          <List id="dic-modal-list" className={classes.list}>
          {book.currentReadingStory.notes.map((note) =>
            note.id_mark === props.markId ? <ListItem key={note.id} alignItems="flex-start" divider={true}>
              <ListItemText className={classes.listItemText}
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="caption"
                      color="textPrimary"
                      className={classes.author}
                    >
                      {note.user_name}
                    </Typography>
                    {note.text}
                  </React.Fragment>
                }
              />
              </ListItem>
            : ""
            ) }
          </List>
          {/* <Divider /> */}
          <TextField className={classes.commentTextArea}
              id="new-comment-textarea"
              label="Написать комментарий..."
              placeholder="Написать комментарий..."
              multiline
              rowsMax={5}
              variant="outlined"
              value={value}
              onChange={handleChange}
          />
          <Button className={classes.commentSendButton} onClick={onAddNote} variant="outlined" color="primary" size="small" disabled={value === ''}>
              Добавить
          </Button>
          <Button className={classes.commentSendButton} onClick={handleClose} variant="outlined" color="primary" size="small">
              Закрыть
          </Button>
        </CardContent>
      </Card>
    </Modal>
  );
});

export default MarkNotes;