import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import ContentTree from './ContentTree';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import { useMobile } from '../contexts/MobileContext';
import { observer } from "mobx-react-lite";
import { useMst } from "../models/Root";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
      width: drawerWidth,
  },
  drawerContainer: {
      overflow: 'auto',
      marginTop: 70,
      marginLeft: 8,
  },
  closeButton: {
    justifyContent: 'flex-end',
    marginBottom: -48,
  },
}));

const ClippedDrawer = observer((props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { mobile } = useMst();

  const drawer = (
    <div className={classes.drawerContainer}>
      <ContentTree></ContentTree>
    </div>
  );

  const handleDrawerToggle = () => {
    mobile.toggle();
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobile.open}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton className={classes.closeButton} onClick={handleDrawerToggle} color="inherit" aria-label="menu">
            <CloseIcon />
          </IconButton>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
});

export default ClippedDrawer;