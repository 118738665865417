if ( !window.indexedDB ) window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
if ( !window.IDBTransaction ) window.IDBTransaction = window.webkitIDBTransaction || window.msIDBTransaction;
if ( !window.IDBKeyRange ) window.IDBKeyRange = window.webkitIDBKeyRange || window.msIDBKeyRange;
if ( !window.indexedDB ) throw new Error( 'IndexedDB is not awailable' );

const DB_NAME = 'GyossStory';
export const ANNOTATION_STORE_NAME = 'annotations';
export const TEXT_STORE_NAME = 'texts';
export const TOOLTIP_STORE_NAME = 'tooltips';

// Функция оборачивает обращение к indexedDB.open в Promise
function openDatabasePromise( keyPath ) {
  return new Promise( ( resolve, reject ) => {
    const dbOpenRequest = window.indexedDB.open( DB_NAME, 2 );

    dbOpenRequest.onblocked = () => {
      reject( 'Требуется обновление структуры базы данных, хранимой в вашем браузере, ' +
        'но браузер уведомил о блокировке базы данных.' );
    };

    dbOpenRequest.onerror = err => {
      console.log( 'Unable to open indexedDB ' + DB_NAME );
      console.log( err );
      reject( 'Невозможно открыть базу данных, либо при её открытии произошла неисправимая ошибка.' +
       ( err.message ? 'Техническая информация: ' + err.message : '' ) );
    };

    dbOpenRequest.onupgradeneeded = event => {
      const db = event.target.result;
      try {
        db.deleteObjectStore( ANNOTATION_STORE_NAME );
        db.deleteObjectStore( TEXT_STORE_NAME );
        db.deleteObjectStore( TOOLTIP_STORE_NAME );
      } catch ( err ) { console.log( err ); }
      db.createObjectStore( ANNOTATION_STORE_NAME, { keyPath } );
      db.createObjectStore( TEXT_STORE_NAME, { keyPath } );
      db.createObjectStore( TOOLTIP_STORE_NAME, { keyPath } );
    };

    dbOpenRequest.onsuccess = () => {
      // console.info( 'Successfully open indexedDB connection to ' + DB_NAME );
      resolve( dbOpenRequest.result );
    };

    dbOpenRequest.onerror = reject;
  } );
}

// Оборачиваем функции от ObjectStore, поддерживающие интерфейс IDBRequest
// в вызов с использованием Promise
function wrap( methodName ) {
  return function() {
    const [ objectStore, ...etc ] = arguments;
    return new Promise( ( resolve, reject ) => {
      const request = objectStore[ methodName ]( ...etc );
      request.onsuccess = () => resolve( request.result );
      request.onerror = reject;
    } );
  };
}

const clearPromise = wrap( 'clear' ) ;
const deletePromise = wrap( 'delete' );
const getAllPromise = wrap( 'getAll' );
const getPromise = wrap( 'get' );
const putPromise = wrap( 'put' );

export default class IndexedDbRepository {

  dbConnection;
  error;
  openDatabasePromise;

  constructor( keyPath ) {
    this.error = null;
    this.keyPath = keyPath;

    // конструктор нельзя объявить как async
    // поэтому вынесено в отдельную функцию
    this.openDatabasePromise = this._openDatabase( keyPath );
  }

  async _openDatabase( keyPath ) {
    try {
      this.dbConnection = await openDatabasePromise( keyPath );
    } catch ( error ) {
      this.error = error;
      throw error;
    }
  }

  async _tx( objectName, txMode, callback ) {
    await this.openDatabasePromise; // await db connection
    const transaction = this.dbConnection.transaction( [ objectName ], txMode );
    const objectStore = transaction.objectStore( objectName );
    return await callback( objectStore );
  }

  async clear( objectName ) {
    return this._tx( objectName, 'readwrite', objectStore => clearPromise( objectStore ) );
  }

  async findAll( objectName ) {
    return this._tx( objectName, 'readonly', objectStore => getAllPromise( objectStore ) );
  }

  async findById( objectName, key ) {
    return this._tx( objectName, 'readonly', objectStore => getPromise( objectStore, key ) );
  }

  async deleteById( objectName, key ) {
    return this._tx( objectName, 'readwrite', objectStore => deletePromise( objectStore, key ) );
  }

  async save( objectName, item ) {
    return this._tx( objectName, 'readwrite', objectStore => putPromise( objectStore, item ) );
  }

}