import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Editor } from '@tinymce/tinymce-react';
import Splitter from 'm-react-splitters';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Properties from './Propreties';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/MessageOutlined';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import SynonymsIcon from '@material-ui/icons/SchoolOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NoteList from '../NoteList';
import LinkProps from './LinkProps';
import TooltipProps from './TooltipProps';
import SynonymsList from '../Synonyms';
import { generateUUID } from '../../utils/utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      className={classes.tabpanel}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} className={classes.box}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: '100%'
  },
  tabpanel: {
    height: 'calc(100% - 50px)',
  },
  box: {
    height: '100%',
    padding: 0,
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: 56,
    marginLeft: 10,
    height: 'calc(100% - 80px)',
    // maxWidth: 'calc(100% - 300px)',
  },
  panel: {
      height: '100%',
      display: 'flex',
  },
  properties: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  editButton: {
    marginTop: '12px',
  },
  notes: {
    height: '100%',
    overflow: 'auto',
    width: '16em',
    paddingLeft: '8px',
  },
  notesCaption: {
    height: '28px',
  },
  panelText: {
    flexGrow: 1,
  },
  textCaption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  commentButton: {
    padding: '2px'
  },
  textLabel: {
    width: '100%'
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'flex'
  },
}));

const initialState = {
  mouseX: null,
  mouseY: null,
};

export default function Mainbar(props) {
  const classes = useStyles();
  const textEditorRef = useRef();
  const [value, setValue] = useState(0);
  const [markId, setMarkId] = useState('');
  const [showComment, setShowComment] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isTooltip, setIsTooltip] = useState(false);
  const [tooltipProps, setTooltipProps] = useState({});
  const [linkProps, setLinkProps] = useState({});
  const [contextMenuState, setContextMenuState] = useState(initialState);
  const [openLinkProps, setOpenLinkProps] = useState(false);
  const [openTooltipProps, setOpenTooltipProps] = useState(false);
  const [editorFullscreen, setEditorfullscreen] = useState(false);
  const [openSynonyms, setOpenSynonyms] = useState(false);
  const [input, setInput] = useState('');
  
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleShowComment = () => {
    setShowComment(!showComment);
  };

  const handleOpenSynonyms = (event) => {
    setOpenSynonyms(true);
  }

  const handleCloseSynonyms = () => {
    setOpenSynonyms(false);
  };

  const handleSelWord = (word) => {
    setOpenSynonyms(false);
    textEditorRef.current.editor.selection.setContent(word);
  }

  const handleFullscreen = () => {
    setEditorfullscreen(!editorFullscreen);
    props.onFullscreen();
  };

  const handleSelectionChange = (event, editor) => {
    let range = editor.selection.getRng();
    if (range.startContainer.parentNode.nodeName === 'SPAN' && range.startContainer.parentNode.getAttribute('style') !== null) {
      setMarkId(range.startContainer.parentNode.getAttribute('id'));
    } else {
      setMarkId('');
    }

    if (range.startContainer === range.endContainer && range.startOffset !== range.endOffset) {
      setInput(editor.selection.getSel().toString().trim());
    } else {
      setInput('');  
    }
  }

  const handleContextMenu = (event, editor) => {
    event.preventDefault();
    setContextMenuState({
      mouseX: event.screenX,
      mouseY: event.screenY - 75,
    });

    let range = editor.selection.getRng();
    let link = { 
      type: 'url',
      url: '',
      text: range.toString(),
      target: '_blanck'
    };
    let tooltip = { 
      id: '',
      text: range.toString(),
      html: ''
    };

    if (range.startContainer.parentNode.nodeName === 'A') {
      const linkNode = range.startContainer.parentNode;

      link = { 
        type: linkNode.classList.contains('story') ? 'story' : 'url',
        url: linkNode.getAttribute('href'),
        text: linkNode.innerText,
        target: linkNode.getAttribute('target')
      };
      setIsLink(true);
      setIsTooltip(false);
    } else if (range.startContainer.parentNode.nodeName === 'HTMLTOOLTIP') {
      const tooltipNode = range.startContainer.parentNode;

      tooltip = { 
        id: tooltipNode.getAttribute('tooltipid'),
        text: tooltipNode.innerText,
        html: ''
      };
      setIsTooltip(true);
      setIsLink(false);
    } else {
      setIsLink(false);
      setIsTooltip(false);
    }
    setLinkProps(link);
    setTooltipProps(tooltip);
  }

  const handleCloseContextMenu = () => {
    setContextMenuState(initialState);
  };

  const handleDeleteLink = () => {
    setContextMenuState(initialState);

    let range = textEditorRef.current.editor.selection.getRng();
    if (range.startContainer.parentNode.nodeName === 'A') {
      range.startContainer.parentNode.replaceWith(range.startContainer.parentNode.textContent);
      props.onTextChange(textEditorRef.current.editor.getContent(), textEditorRef.current.editor);
    }
  };

  const handleDeleteTooltip = () => {
    setContextMenuState(initialState);

    let range = textEditorRef.current.editor.selection.getRng();
    if (range.startContainer.parentNode.nodeName === 'HTMLTOOLTIP') {
      props.onDeleteTooltip(range.startContainer.parentNode.getAttribute('tooltipid'));
      range.startContainer.parentNode.replaceWith(range.startContainer.parentNode.textContent);
      props.onTextChange(textEditorRef.current.editor.getContent(), textEditorRef.current.editor);
    }
  };

  const handleOpenLinkProps = () => {
    setContextMenuState(initialState);
    setOpenLinkProps(true);
  };

  const handleOpenTooltipProps = () => {
    setContextMenuState(initialState);
    if (tooltipProps.id !== '') {
      props.tooltips.forEach(tooltip => {
        if (tooltip.id_tooltip == tooltipProps.id) {
          let newTooltipProps = tooltipProps;
          newTooltipProps.html = tooltip.text;
          setTooltipProps(newTooltipProps);
        }
      });
    }
    setOpenTooltipProps(true);
  };

  const handleCloseLinkProps = (linkProps) => {
    setOpenLinkProps(false);

    if (linkProps !== null) {
      let range = textEditorRef.current.editor.selection.getRng();
      let oldLink = null;

      if (range.startContainer.parentNode.nodeName === 'A') {
        oldLink = range.startContainer.parentNode;
      }

      let link = document.createElement('a');
      link.innerText = linkProps.text;
      link.setAttribute("href", linkProps.url);
      link.setAttribute("target", linkProps.target);
      if (linkProps.type === 'story') {
        link.classList.add('story');
      } else {
        link.classList.remove('story');
      }
      if (oldLink === null) {
        range.deleteContents();
        range.insertNode(link);
      } else {
        oldLink.replaceWith(link);
      }

      props.onTextChange(textEditorRef.current.editor.getContent(), textEditorRef.current.editor);
    }
  };

  const handleCloseTooltipProps = (tooltipProps) => {
    setOpenTooltipProps(false);

    if (tooltipProps !== null) {
      let range = textEditorRef.current.editor.selection.getRng();
      let oldTooltip = null;

      if (range.startContainer.parentNode.nodeName === 'HTMLTOOLTIP') {
        oldTooltip = range.startContainer.parentNode;
      }

      let tooltip = document.createElement('htmltooltip');
      let tooltipId = tooltipProps.id;
      if (tooltipId == '') {
        tooltipId = generateUUID();
      }
      tooltip.innerText = tooltipProps.text;
      tooltip.setAttribute("tooltipid", tooltipId);
      tooltip.style.color = 'blue';
      tooltip.style.fontStyle = 'italic';
      tooltip.style.cursor = 'pointer';
      if (oldTooltip === null) {
        range.deleteContents();
        range.insertNode(tooltip)
      } else {
        oldTooltip.replaceWith(tooltip);
      }

      const tooltipInBase = {
        id_story: props.id_story,
        id_tooltip: tooltipId,
        text: tooltipProps.html
      }
      props.onAddTooltip(tooltipInBase);
      props.onTextChange(textEditorRef.current.editor.getContent(), textEditorRef.current.editor);
    }
  }

  return (
      <main className={classes.content} >
       <Splitter
          position="horizontal"
          primaryPaneMaxHeight="90%"
          primaryPaneMinHeight="10%"
          primaryPaneHeight="40%"
          minimalizedPrimaryPane={editorFullscreen}
          postPoned={false}
        >  
        <div className={classes.root}>
      <AppBar position="static" color="default" className={editorFullscreen === true ? classes.hidden : classes.visible}>
        <Tabs value={value} onChange={handleChangeTab} aria-label="simple tabs example">
          <Tab label="Аннотация" {...a11yProps(0)} />
          <Tab label="Свойства" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <Editor
         apiKey="1g6cjfyl5h07nig8xd17r7c79ruf9j9e3kbkoc0g9xobmwqd"
         initialValue=""
         value={props.annotation}
         init={{
          //  height: 'calc(100% - 30px)',
          height: '100%',
           menubar: false,
           resize : false,
           plugins: [
            'advlist autolink lists link image charmap print preview',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
           ],
           toolbar:
             'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
         }}
         onEditorChange={props.onAnnotaionChange}
       />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Properties 
          edit_status={props.edit_status} 
          onEditStatusChange={props.onEditStatusChange}
          edit_now={props.edit_now} 
          onEditNowChange={props.onEditNowChange}
          draft={props.draft} 
          onDraftChange={props.onDraftChange}
          public_allow={props.public_allow}
          public_id={props.public_id}
          onPublicAllowChange={props.onPublicAllowChange}
          need_donat={props.need_donat}
          onNeedDonatChange={props.onNeedDonatChange}
        />
      </TabPanel> 
      </div>
      <div className={classes.panel}>
        <div className={classes.panelText}>  
          <Typography variant="subtitle2" className={classes.textCaption}>
            <div className={classes.textLabel}>
              Текст:
            </div>
            <Tooltip title="Словарь синонимов">
              <IconButton
                color="primary"
                onClick={handleOpenSynonyms}
                variant="outlined"
                className={classes.commentButton}
              >
                <SynonymsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Распахнуть/сжать редактор">
              <IconButton
                color="primary"
                onClick={handleFullscreen}
                variant="outlined"
                className={classes.commentButton}
              >
                <FullscreenIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Показать/скрыть комментарии">
              <IconButton
                color="primary"
                onClick={handleShowComment}
                variant="outlined"
                className={classes.commentButton}
              >
                <CommentIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Editor
          apiKey="1g6cjfyl5h07nig8xd17r7c79ruf9j9e3kbkoc0g9xobmwqd"
          initialValue=""
          ref={textEditorRef}
          value={props.text}
          init={{
            content_style: "body {font-size: 14pt;}",
            height: 'calc(100% - 28px)',
            menubar: false,
            resize : false,
            verify_html : false,
            plugins: [
              'advlist lists image charmap print preview',
              'searchreplace visualblocks code fullscreen image',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | image bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
            contextmenu_never_use_native: true,
            /* enable title field in the Image dialog*/
            image_title: true,
            /* enable automatic uploads of images represented by blob or data URIs*/
            automatic_uploads: true,
            /*
              URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
              images_upload_url: 'postAcceptor.php',
              here we add custom filepicker only to Image dialog
            */
            file_picker_types: 'image',
            /* and here's our custom image picker*/
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');

              /*
                Note: In modern browsers input[type="file"] is functional without
                even adding it to the DOM, but that might not be the case in some older
                or quirky browsers like IE, so you might want to add it to the DOM
                just in case, and visually hide it. And do not forget do remove it
                once you do not need it anymore.
              */

              input.onchange = function () {
                var file = this.files[0];

                var reader = new FileReader();
                reader.onload = function () {
                  /*
                    Note: Now we need to register the blob in TinyMCEs image blob
                    registry. In the next release this part hopefully won't be
                    necessary, as we are looking to handle it internally.
                  */
                  var id = 'blobid' + (new Date()).getTime();
                  var blobCache =  textEditorRef.current.editor.editorUpload.blobCache;
                  var base64 = reader.result.split(',')[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);

                  /* call the callback and populate the Title field with the file name */
                  cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
              };

              input.click();
            },
          }}
          onEditorChange={props.onTextChange}
          onSelectionChange={handleSelectionChange}
          onContextMenu={handleContextMenu}
          />
          <Menu
            keepMounted
            open={contextMenuState.mouseY !== null}
            onClose={handleCloseContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenuState.mouseY !== null && contextMenuState.mouseX !== null
                ? { top: contextMenuState.mouseY, left: contextMenuState.mouseX }
                : undefined
            }
          >
            <MenuItem onClick={handleOpenLinkProps} disabled={isTooltip}>{isLink ? 'Редактировать ссылку' : 'Вставить ссылку'}</MenuItem>
            <MenuItem onClick={handleOpenTooltipProps} disabled={isLink}>{isTooltip ? 'Редактировать сноску' : 'Вставить сноску'}</MenuItem>
            <MenuItem onClick={handleDeleteLink} disabled={!isLink}>Удалить ссылку</MenuItem>
            <MenuItem onClick={handleDeleteTooltip} disabled={!isTooltip}>Удалить сноску</MenuItem>
          </Menu>
          <LinkProps open={openLinkProps} onClose={handleCloseLinkProps} link={linkProps} treeData={props.treeData}/>
          <TooltipProps open={openTooltipProps} onClose={handleCloseTooltipProps} tooltip={tooltipProps} treeData={props.treeData}/>
          <SynonymsList open={openSynonyms} handleClose={handleCloseSynonyms} handleSelWord={handleSelWord} input={input}/>
        </div>
        {showComment ?
        <div className={classes.notes}>
          <Typography variant="subtitle2" className={classes.notesCaption}>
              Комментарии:
          </Typography>
          <NoteList notes={props.notes !== undefined ? props.notes : []} markId={markId}/>
        </div>
        : "" }
       </div>
      </Splitter>
      </main>
  );
}