import React, { useEffect, useState, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react-lite";
import { isValidReference } from "mobx-state-tree";
import { useMst } from "../../models/Root";
import { useValidateUser } from '../../components/AuthProvider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  loading: {
      display: 'flex',
      justifyContent: 'center',
  },
  contentNoEdit: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: 64,
    maxWidth: 800,
    margin: '0 auto',
    fontSize: 18,
    lineHeight: '1.35em',
    fontFamily: 'Roboto',
    textAlign: 'justify',
    color: '#333',
    userSelect: 'none',
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: 64,
    maxWidth: 800,
    margin: '0 auto',
    fontSize: 18,
    lineHeight: '1.35em',
    fontFamily: 'Roboto',
    textAlign: 'justify',
    color: '#333',
  },
  fragment: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Mainbar = observer((props) => {
  const classes = useStyles();
  const { book } = useMst();
  const validUser = useValidateUser('ADMIN AUTHOR BETA_READER MODERATOR');
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltip, setTooltip] = useState('');

  const handleClickTooltip = (event) => {
    setAnchorEl(event.currentTarget);
    setTooltip(book.currentReadingStory.getTooltip(event.currentTarget.getAttribute('tooltipid')));
    console.log(book.currentReadingStory.getTooltip(event.currentTarget.getAttribute('tooltipid')));
  };

  const handleCloseTooltip = () => {
    setAnchorEl(null);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const handleCopy = (e) => {
    e.clipboardData.setData('text/plain', 'Копирование текстов с этого сайта не приветствуется...');
    e.clipboardData.setData('text/html', '<b>Копирование текстов с этого сайта не приветствуется...</b>');
    e.preventDefault();
  }

  const handlePrevent = (e) => {
    if (!validUser) {
      e.preventDefault();
    }
  }

  const handleScroll = (e) => {
    localStorage.setItem('story' + props.storyId + 'Offset', window.pageYOffset);
  }

  useEffect(() => {
    book.clearCurrentReading();
    if (props.storyId != null) {
      book.loadCurrentReading(props.storyId, props.public);
    }
  }, []);

  useEffect(() => {
    if (book.currentReadingStory.text.length > 0) {
      if (localStorage.getItem('story' + props.storyId + 'Offset') !== null) {
        window.scrollTo(0, localStorage.getItem('story' + props.storyId + 'Offset'));
      }

      window.addEventListener('scroll', handleScroll);

      let tooltips = document.querySelectorAll('htmltooltip');
      tooltips.forEach((tooltip) => {
        tooltip.addEventListener('click', handleClickTooltip);
      })

      return () => {
        window.removeEventListener('scroll', handleScroll);

        tooltips.forEach((tooltip) => {
          tooltip.removeEventListener('click', handleClickTooltip);
        })
      }
    }
  }, [book.currentReadingStory.text]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
      <main className={validUser ? classes.content : classes.contentNoEdit} 
        onMouseDown={handlePrevent} 
        onCopy={handleCopy}
        onContextMenu={handleContextMenu}
        style={{ cursor: 'context-menu' }}  
      >
        {(!isValidReference(() => book.currentReadingStory) || book.currentReadingStory.text.length === 0) ? 
          <div className={classes.loading}>
            <CircularProgress/> 
          </div>
          :
          <div id="content" name="content" dangerouslySetInnerHTML={{__html:  book.currentReadingStory.text}}/>
        }
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseTooltip}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {<div className={classes.fragment} dangerouslySetInnerHTML={{__html:  tooltip}}/>}
      </Popover>
      </main>
  );
});

export default Mainbar;