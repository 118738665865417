import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HistoryIcon from '@material-ui/icons/History';
import DonatIcon from '@material-ui/icons/MonetizationOnOutlined';
import UserIcon from '@material-ui/icons/PersonSharp';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ExitIcon from '@material-ui/icons/ExitToAppOutlined';
import ModerateIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import EditIcon from '@material-ui/icons/Edit';
import MoreIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import { useAuth, authFetch, logout, getRole } from './AuthProvider';
import { observer } from "mobx-react-lite";
import { useMst } from "../models/Root";
import HistoryList from './History';
import { donatLink } from '../utils/constants';
import { ReactComponent as LogoIcon } from '../icons/logo.svg'

const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    moreButton: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    iconButton: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    toolBarTitle: {
      flexGrow: 1,
    },
    listItemIcon: {
      minWidth: 28,
    },
    logo: {
      width: 38, 
      height: 38,
      display: 'block',
      padding: 4,
      marginRight: 6,
      background: 'white',
      borderRadius: '50%',
      WebkitAnimationName: 'rotation',
      WebkitAnimationDuration: '30s',
      WebkitAnimationIterationCount: 'infinite',
      // WebkitAnimationTimingFunction: 'steps(2, jump-end)',
      WebkitAnimationTimingFunction: 'linear',
      MozAnimationName: 'rotation',
      MozAnimationDuration: '30s',
      MozAnimationIterationCount: 'infinite',
      // MozAnimationTimingFunction: 'steps(2, jump-end)',
      MozAnimationTimingFunction: 'linear',
      OAnimationName: 'rotation',
      OAnimationDuration: '30s',
      OAnimationIterationCount: 'infinite',
      // OAnimationTimingFunction: 'steps(2, jump-end)',
      OAnimationTimingFunction: 'linear',
      animationName: 'rotation',
      animationDuration: '30s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear'
      // animationTimingFunction: 'steps(2, jump-end)',
    }
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const Navbar = observer(() => {
    const classes = useStyles();
    const [logged] = useAuth();
    const { mobile } = useMst();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openHistory, setOpenHistory] = useState(false);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCloseHistory = () => {
      setOpenHistory(false);
    };

    const handleDonatClick = () => {
      window.open(donatLink, '_blank');
    }

    function handleLogout() {
        authFetch(process.env.REACT_APP_API_URL + '/users/logout', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            credentials: 'include',
        })
          .then(r => r.json())
          .then(r => {
            if ('success' === r.status) {
              logout();
            } else {
              console.error(r);  
            }
          })
          .catch(err => {
            console.error(err);
          })
    }

    function userMenu() {
      return (
        <div>
        <Tooltip title="Отблагодарить, помочь проекту">
          <IconButton className={classes.iconButton} color="inherit" onClick={handleDonatClick}>
            <DonatIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="История изменений">
          <IconButton className={classes.iconButton} color="inherit" onClick={()=>setOpenHistory(true)}>
            <HistoryIcon />
          </IconButton>
        </Tooltip>
          {logged && (getRole() === 'ADMIN' || getRole() === 'AUTHOR') ? 
            <IconButton className={classes.iconButton} aria-label="editor" color="inherit" component={RouterLink} to="/editor">
                <EditIcon />
            </IconButton>
            : ""
          }
          {/* {logged && (getRole() === 'ADMIN' || getRole() === 'AUTHOR' || getRole() === 'MODERATOR') ? 
            <IconButton className={classes.iconButton} aria-label="editor" color="inherit" component={RouterLink} to="/moderator">
                <ModerateIcon />
            </IconButton>
            : ""
          } */}
          {!logged ?
            <Tooltip title="Войти">
              <IconButton className={classes.iconButton} color="inherit" component={RouterLink} to="/login">
                <UserIcon/>
              </IconButton>
            </Tooltip>
          : 
           <Tooltip title="Выйти">
              <IconButton className={classes.iconButton} color="inherit" onClick={handleLogout}>
                <ExitIcon/>
              </IconButton>
            </Tooltip>
          }
          <IconButton className={classes.moreButton} color="inherit" aria-label="more" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <MoreIcon/>
          </IconButton>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleDonatClick}>
              <ListItemIcon className={classes.listItemIcon}>
                <DonatIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Помочь проекту"/>
            </MenuItem>
            <MenuItem onClick={()=>setOpenHistory(true)}>
              <ListItemIcon className={classes.listItemIcon}>
                <HistoryIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="История изменений"/>
            </MenuItem>
            {logged && (getRole() === 'ADMIN' || getRole() === 'AUTHOR') ? 
            <MenuItem component={RouterLink} to="/editor">
              <ListItemIcon className={classes.listItemIcon}>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Редактор"/>
            </MenuItem>
            : ""
            }
            {/* {logged && (getRole() === 'ADMIN' || getRole() === 'AUTHOR' || getRole() === 'MODERATOR') ? 
            <MenuItem component={RouterLink} to="/moderator">
              <ListItemIcon className={classes.listItemIcon}>
                <ModerateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Работа с комментариями"/>
            </MenuItem>
            : ""
            } */}
            {logged ?
              <MenuItem onClick={handleLogout}>
                <ListItemIcon className={classes.listItemIcon}>
                  <ExitIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Выйти"/>
              </MenuItem>
            :
            <MenuItem component={RouterLink} to="/login">
                <ListItemIcon className={classes.listItemIcon}>
                  <UserIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Войти"/>
              </MenuItem>
            }
          </StyledMenu>
          <HistoryList open={openHistory} handleClose={handleCloseHistory}/>
        </div>
      );
    }

    const handleDrawerToggle = () => {
      mobile.toggle();
    };

    return (
        <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
            <IconButton edge="start" className={classes.menuButton} onClick={handleDrawerToggle} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <LogoIcon className={classes.logo}/>
            <Typography variant="h6" className={classes.toolBarTitle}>
              Eny Gyoss
            </Typography>
             {userMenu()}
          </Toolbar>
        </AppBar>
    );
});

export default Navbar;