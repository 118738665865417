import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react-lite";
import { useMst } from "../models/Root";
import { useAuth, getUserId } from './AuthProvider';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MoreIcon from '@material-ui/icons/AddBoxOutlined';
import HideIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';

const useStyles = makeStyles((theme) => ({
  Comment: {
    marginTop: 16,
    marginBottom: 10,
    minHeight: 50,
  },
  Avatar: {
    float: 'left',
  },
  Body: {
    marginLeft: 50,
  },
  Author: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  Date: {
    fontWeight: 'normal',
    marginLeft: 10,
  },
  TagAuthor: {
    fontSize: 10,
    fontWeight: 'normal',
    backgroundColor: '#4582af',
    color: 'white',
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 4,
    borderRadius: '0.25em',
  },
  Text: {
    fontSize: 14,
    marginTop: 0,
    marginBottom: 0,
  },
  Answer: {
    '&:hover': {
      textDecoration: 'none',
    },
    marginTop: 8,
  },
  More: {
    fontSize: 13,
    marginTop: 8,
  },
  MoreIcon: {
    fontSize: '1rem',
  },
  MoreText: {
    verticalAlign: 'top',
  },
  MoreLink:{
    '&:hover': {
      textDecoration: 'none',
    },
    color: 'darkcyan',
  },
  ChildArea: {
    marginTop: -10,
    marginLeft: 8,
    paddingLeft: 12,
    borderLeft: '1px dotted darkcyan',
  },
  AnswerArea: {
    marginTop: 8,
    marginLeft: 8,
    paddingLeft: 12,
    borderLeft: '1px dotted darkcyan',
  },
  commentTextArea: {
    width: '100%',
    '& textarea': {
      fontSize: theme.typography.body2.fontSize,
    }
  },
  commentSendButton: {
    marginBlockStart: '0.5rem',  
  },
}));

const Comment = observer((props) => {
  const classes = useStyles();
  const { book } = useMst();
  const [logged] = useAuth();
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState('');

  const getMonthName = (month) => {
    switch (month) {
      case 1:
        return 'января';
      case 2:
        return 'февраля';
      case 3:
        return 'марта';
      case 4:
        return 'апреля';
      case 5:
        return 'мая';
      case 6:
        return 'июня';
      case 7:
        return 'июля';
      case 8:
        return 'августа';
      case 9:
        return 'сентября';
      case 10:
        return 'октября';
      case 11:
        return 'ноября';
      case 12:
        return 'декабря';
    }
  };

  const getDate = () => {
    let comDate = new Date(Date.parse(props.data.date_create));
    let nowDate = new Date(Date.now());

    let hour = comDate.getHours() < 10 ? '0' + comDate.getHours() : comDate.getHours();
    let minutes = comDate.getMinutes() < 10 ? '0' + comDate.getMinutes() : comDate.getMinutes();
    let time = hour + ':' + minutes;

    if (comDate.getFullYear() === nowDate.getFullYear() && comDate.getMonth() === nowDate.getMonth() && comDate.getDate() === nowDate.getDate()) {
      return 'сегодня в ' + time;
    } else if (comDate.getFullYear() === nowDate.getFullYear() && comDate.getMonth() === nowDate.getMonth() && comDate.getDate() === nowDate.getDate() - 1) {
      return 'вчера в ' + time;
    } else if (comDate.getFullYear() === nowDate.getFullYear() && comDate.getMonth() === nowDate.getMonth() && comDate.getDate() === nowDate.getDate() - 2) {
      return 'позавчера в ' + time;
    }

    if (comDate.getFullYear() === nowDate.getFullYear()) {
      return comDate.getDate() + ' ' + getMonthName(comDate.getMonth() + 1) + ' в ' + time;
    } else {
      return comDate.getDate() + ' ' + getMonthName(comDate.getMonth() + 1) + ' ' + comDate.getFullYear() + ' в ' + time;
    }
  };

  const handleAnswerClick = () => {
    if (book.commentAnswering !== props.data.id) {
      book.setCommentAnswering(props.data.id);
    } else {
      book.setCommentAnswering(0);
    }
    setValue('');
  };

  const handleMoreClick = () => {
    props.data.loadChildComments();
    setExpanded(true);
  };

  const handleHideClick = () => {
    setExpanded(false);
  };

  const handleChangeText = (event) => {
    setValue(event.target.value.slice(0, 10000));
  };

  const handleSendClick = () => {
    let comment = {
      id_user: getUserId(),
      id_parent: props.data.id,
      text: value,
    }

    props.data.addChildComment(comment);
    book.setCommentAnswering(0);
    setValue('');
    setExpanded(true);
  };

  useEffect(() => {
    setExpanded(false);
    setValue('');
  }, [book.selectedStory]);

  return (
    <div className={classes.Comment}>
      <div className={classes.Avatar}>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </div>
      <div className={classes.Body}>
        <div className={classes.Author}>
          {props.data.user_name}
          {props.data.is_author !== 0 ?
            <span className={classes.TagAuthor}>автор</span>
            : ""
          }
          <span className={classes.Date}>{getDate()}</span>
        </div>
        <div className={classes.Text} dangerouslySetInnerHTML={{__html: '<p style="margin-top:8px;margin-bottom:0">' + props.data.text.replace(/\n/gi, '</p><p style="margin-top:8px;margin-bottom:0">') + '</p>'}} />
        {logged ?
          <div>
            <div>
              <Link className={classes.Answer} onClick={handleAnswerClick} component="button" variant="body2">
                { book.commentAnswering === props.data.id ? <span>отмена</span> : <span>ответить</span> }
              </Link>
            </div>
            { book.commentAnswering === props.data.id ?
              <div className={classes.AnswerArea}>
                <TextField className={classes.commentTextArea}
                          id="new-comment-textarea"
                          label={"Написать комментарий (" + (10000 - value.length).toString() + ')'}
                          placeholder="Написать комментарий..."
                          multiline
                          rowsMax={5}
                          variant="outlined"
                          value={value}
                          onChange={handleChangeText}
                />
                <Button className={classes.commentSendButton} onClick={handleSendClick} variant="outlined" color="primary" size="small" disabled={value.length === 0}>
                    Отправить
                </Button>
              </div>
              : ""
            }
          </div>
          : ""
        }
      </div>
      {props.data.has_children != false ?
        <div className={classes.More}>
          {expanded === false ?
            <Link className={classes.MoreLink} onClick={handleMoreClick} component="button">
              <MoreIcon className={classes.MoreIcon}/> <span className={classes.MoreText}>раскрыть ветвь</span>
            </Link>
          : 
            <div>
              <Link className={classes.MoreLink} onClick={handleHideClick} component="button">
                <HideIcon className={classes.MoreIcon}/>
              </Link>
              <div className={classes.ChildArea}>
                {props.data.children.map((comment, index) => 
                  <Comment
                    data={comment}
                    key={index}
                    index={index}
                  />)
                }
              </div>
            </div>
          }
        </div>
        : ""
      }
    </div>
  );
});

export default Comment;