import { createAuthProvider } from 'react-token-auth';

export const storageKey = 'GYOSS_AUTH_TOKEN';
export const memoryStore = createMemoryStore();

export const [useAuth, authFetch, login, logout] =
    createAuthProvider({
        accessTokenKey: 'accessToken',
        localStorageKey: storageKey,
        onUpdateToken: (token) => fetch(process.env.REACT_APP_API_URL + '/users/update-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            credentials: 'include',
            body: JSON.stringify({refreshToken: token.refreshToken})
        })
        .then(r => r.json())
        .then(r => {
            if ('success' === r.status) {
                return r.message
            } else {
                return []
            }
        })
        .catch(err => {
            console.error(err)
            logout()
            window.location.reload()
        }),
        storage: memoryStore
    });

export function getRole() {
    let data = memoryStore.getItem(storageKey);
    let token = JSON.parse(data).accessToken;

    let split = token.split('.');
    if (split.length < 2) {
        return '';
    }
    try {
        let jwt = JSON.parse(atob(token.split('.')[1]));
        if (jwt && jwt.role && jwt.role.length > 0) {
            return jwt.role;
        }
        else {
            return '';
        }
    }
    catch (e) {
        return '';
    }
}

export function getUserId() {
    let data = memoryStore.getItem(storageKey);
    let token = JSON.parse(data).accessToken;
    let split = token.split('.');
    if (split.length < 2) {
        return '';
    }
    try {
        let jwt = JSON.parse(atob(token.split('.')[1]));
        
        if (jwt && jwt.sub && jwt.sub > 0) {
            return jwt.sub;
        }
        else {
            return '';
        }
    }
    catch (e) {
        return '';
    }
}

export function getUserName() {
    let data = memoryStore.getItem(storageKey);
    let token = JSON.parse(data).accessToken;

    let split = token.split('.');
    if (split.length < 2) {
        return '';
    }
    try {
        let jwt = JSON.parse(atob(token.split('.')[1]));
        if (jwt && jwt.name && jwt.name.length > 0) {
            return jwt.name;
        }
        else {
            return '';
        }
    }
    catch (e) {
        return '';
    }
}

export function useValidateUser(roles) {
    const [logged] = useAuth();

    if (logged) {
        if (roles && roles.length > 0) {
            let rolesArr = roles.split(' ');
            if (rolesArr.indexOf(getRole()) !== -1) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    } else {
        return false;
    }
}

function createMemoryStore () {
    let data = {};
    const getItem = function (key) {
        return data[key];
    };
    const setItem = function (key, value) {
        // console.log(value);
        data[key] = value;
    };
    const removeItem = function (key) {
        delete data[key];
    };
    return { getItem: getItem, setItem: setItem, removeItem: removeItem };
};

export async function getIP() {
    try {
        let response = await fetch('https://api.ipify.org?format=json');
        let ip = await response.json();
        return ip;
    } catch(err) {
        console.error(err);
    }

}