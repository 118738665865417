import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { useMst } from "../../models/Root";

const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolBarTitle: {
      flexGrow: 1,
      justifyContent: 'flex-start',
    },
    closeButton: {
      justifyContent: 'flex-end',
    },
}));

export default function Navbar(props) {
    const classes = useStyles();
    const history = useHistory();
    const { book, tree } = useMst();

    const onClose = () => {
        tree.setSelected(0);
        tree.setExpanded([]);
        history.push("/");
    }

    return (
      <AppBar position="fixed" className={classes.appBar} color="default">
        <Toolbar variant="dense">
          <div  className={classes.toolBarTitle}>
          </div>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          </Toolbar>
      </AppBar>
    );
}