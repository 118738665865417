import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { observer } from "mobx-react-lite";
import { Editor } from '@tinymce/tinymce-react';
import { Typography } from '@material-ui/core';
import LinkProps from './LinkProps';

function isObjectEmpty(value) {
  return (
      Object.prototype.toString.call(value) === '[object Object]' && JSON.stringify(value) === '{}'
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    minWidth: 300,
    maxWidth: 840,
    maxHeight: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexWrap: 'wrap',
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  cardContent: {
    padding: '10px',
    '&:last-child': {
      paddingBottom: '10px',
    }
  },
  linkHeder: {
    marginBlockStart: 0,
    marginBlockEnd: '0.5em',
  },
  button: {
    marginBlockStart: '0.5rem',
    marginRight: '0.5rem',
  },
  textField: {
    margin: theme.spacing(1),
    width: '800px',
  },
  htmlField: {
    margin: theme.spacing(1),
    width: '800px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '510px',
  },
  footer: {
    textAlign: 'right',
  }
}));

const initialState = {
  mouseX: null,
  mouseY: null,
};

const TooltipProps = observer((props) => {
  const classes = useStyles();
  const textEditorRef = useRef();
  const [id, setId] = useState('');
  const [text, setText] = useState('');
  const [html, setHtml] = useState('');
  const [valid, setValid] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [linkProps, setLinkProps] = useState({});
  const [contextMenuState, setContextMenuState] = useState(initialState);
  const [openLinkProps, setOpenLinkProps] = useState(false);

  const handleContextMenu = (event, editor) => {
    event.preventDefault();
    setContextMenuState({
      mouseX: event.screenX,
      mouseY: event.screenY - 75,
    });

    let range = editor.selection.getRng();
    let link = { 
      type: 'url',
      url: '',
      text: range.toString(),
      target: '_blanck'
    };

    if (range.startContainer.parentNode.nodeName === 'A') {
      const linkNode = range.startContainer.parentNode;

      link = { 
        type: linkNode.classList.contains('story') ? 'story' : 'url',
        url: linkNode.getAttribute('href'),
        text: linkNode.innerText,
        target: linkNode.getAttribute('target')
      };
      setIsLink(true);
    } else {
      setIsLink(false);
    }
    setLinkProps(link);
  }

  const handleCloseContextMenu = () => {
    setContextMenuState(initialState);
  };

  const handleDeleteLink = () => {
    setContextMenuState(initialState);

    let range = textEditorRef.current.editor.selection.getRng();
    if (range.startContainer.parentNode.nodeName === 'A') {
      range.startContainer.parentNode.replaceWith(range.startContainer.parentNode.textContent);
      handleChangeHtml(textEditorRef.current.editor.getContent(), textEditorRef.current.editor);
    }
  };

  const handleOpenLinkProps = () => {
    setContextMenuState(initialState);
    setOpenLinkProps(true);
  };

  const handleCloseLinkProps = (linkProps) => {
    setOpenLinkProps(false);

    if (linkProps !== null) {
      let range = textEditorRef.current.editor.selection.getRng();
      let oldLink = null;

      if (range.startContainer.parentNode.nodeName === 'A') {
        oldLink = range.startContainer.parentNode;
      }

      let link = document.createElement('a');
      link.innerText = linkProps.text;
      link.setAttribute("href", linkProps.url);
      link.setAttribute("target", linkProps.target);
      if (linkProps.type === 'story') {
        link.classList.add('story');
      } else {
        link.classList.remove('story');
      }
      if (oldLink === null) {
        range.deleteContents();
        range.insertNode(link);
      } else {
        oldLink.replaceWith(link);
      }

      handleChangeHtml(textEditorRef.current.editor.getContent(), textEditorRef.current.editor);
    }
  };

  const handleCancel = () => {
    props.onClose(null);
  };

  const handleAccept = () => {
    let tooltip = { 
      id,
      text,
      html
    };
    props.onClose(tooltip);
  };

  const handleChangeText = (event) => {
    setText(event.target.value);
  };

  const handleChangeHtml = (content, editor) => {
    setHtml(content);
  };

  const validateForm = () => {   
    if ((text.trim().length > 0) && (html.trim().length > 0)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  useEffect(() => {
    if (!isObjectEmpty(props.tooltip)) {
      setId(props.tooltip.id);
      setText(props.tooltip.text);
      setHtml(props.tooltip.html);
    }
  }, [props.tooltip, props.tooltip.id, props.tooltip.text, props.tooltip.html]);

  useEffect(() => {
    validateForm();
  }, [text, html]);

  return (
    <Modal
      open={props.open}
      onClose={handleCancel}
      aria-labelledby="dic-modal-title"
      aria-describedby="dic-modal-list"
    >
      <Card className={classes.paper} variant="outlined">
        <CardContent className={classes.cardContent}>
          <h4 id="dic-modal-title" className={classes.linkHeder}>
            Добавление\редактирование сноски
          </h4>
          <Divider />
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="field-text" className={classes.textField} label="Отображаемый текст" value={text} onChange={handleChangeText} fullWidth/>
            <Typography>Текст сноски:</Typography>
            <Editor
              apiKey="1g6cjfyl5h07nig8xd17r7c79ruf9j9e3kbkoc0g9xobmwqd"
              initialValue=""
              value={html}
              ref={textEditorRef}
              init={{
                //  height: 'calc(100% - 30px)',
                height: '400px',
                menubar: false,
                resize : false,
                content_style: "body {font-size: 14pt;}",
                verify_html : false,
                plugins: [
                  'advlist autolink lists image charmap print preview',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                contextmenu_never_use_native: true,  
              }}
              onEditorChange={handleChangeHtml}
              onContextMenu={handleContextMenu}
            />
            <Menu
              keepMounted
              open={contextMenuState.mouseY !== null}
              onClose={handleCloseContextMenu}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenuState.mouseY !== null && contextMenuState.mouseX !== null
                  ? { top: contextMenuState.mouseY, left: contextMenuState.mouseX }
                  : undefined
              }
            >
              <MenuItem onClick={handleOpenLinkProps}>{isLink ? 'Редактировать ссылку' : 'Вставить ссылку'}</MenuItem>
              <MenuItem onClick={handleDeleteLink} disabled={!isLink}>Удалить ссылку</MenuItem>
            </Menu>
            <LinkProps open={openLinkProps} onClose={handleCloseLinkProps} link={linkProps} treeData={props.treeData}/>
          </form>
          <div className={classes.footer}>
            <Button className={classes.button} onClick={handleAccept} disabled={!valid} variant="outlined" color="primary" size="small">
                Принять
            </Button>
            <Button className={classes.button} onClick={handleCancel} variant="outlined" color="primary" size="small">
                Отмена
            </Button>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
});

export default TooltipProps;