import React, { useState, useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import AddIcon from '@material-ui/icons/AddOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  table: {
    minWidth: 300,
    marginTop: '10px',
  },
  tableCell: {
    fontSize: 'large',
  },
  synonym: {
  },
  space: {
    display: 'flex',
    width: '100%'
  },
  buttonClose: {
    marginRight: '8px'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 'large',
  },
  helperText: {
    color: 'red',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minHeight: 200,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function SynonymEditor(props) {
  const classes = useStyles();
  const theme = useTheme();
  const inputField = useRef(null);
  const [rows, setRows] = useState([]);
  const [newSet, setNewSet] = useState(false);
  const [input, setInput] = useState('');
  const [helperText, setHelperText] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAdd = () => {
    setHelperText('');
    let value = inputField.current.value;
    value = value.replace(/[^a-zA-Zа-яА-ЯёЁ0-9,.!?'"\- ]/g, ""); 
    inputField.current.value = value;
    if (value.length >= 3) {
      if (!rows.includes(value)) {
        let tempRows = rows.slice();
        tempRows.push(value);
        tempRows.sort();
        setRows(tempRows);
        setHelperText('');
        inputField.current.value = '';
      } else {
        setHelperText('Такое слово уже есть в списке.');
      }
    } else {
      setHelperText('Нельзя добавить слово из менее чем трех букв.');
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleAdd();
    }
  }

  const handleDeleteSynonym = (index) => {
    let tempRows = rows.slice();
    tempRows.splice(index, 1);
    setRows(tempRows);
  }

  const handleAccept = () => {
    props.handleAcceptSynonymsEdit(newSet, rows);
  }

  useEffect(() => {
    if (props.open) {
      if (props.synonymsRow !== null) {
        let tempRows = props.synonymsRow.words.slice();
        tempRows.unshift(props.synonymsRow.word);
        tempRows.sort();
        setRows(tempRows);
        setNewSet(false);
        setInput('');
      } else {
        setRows([]);
        setNewSet(true);
        setInput(props.inputValue);
      }
    }
  }, [props.open]);

  return (
    <Dialog fullScreen={fullScreen} maxWidth='xl' onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          Редактор синонимов
      </DialogTitle>
      <DialogContent dividers>
          <Paper className={classes.paper}>
            <InputBase
              size="small"
              inputRef={inputField}
              className={classes.input}
              placeholder="новый синоним"
              defaultValue={input}
              inputProps={{ 'aria-label': 'add synonym' }}
              onKeyDown={handleKeyDown}
            />
            <Tooltip title="Добавить синоним">
              <IconButton autoFocus className={classes.iconButton} aria-label="search" onClick={handleAdd}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Paper>
          <FormHelperText id="component-helper-text" className={classes.helperText} visibility={helperText.length > 0 ? "" : "hidden"}>{helperText}</FormHelperText>
          <TableContainer>
          <Table className={classes.table} aria-label="simple table" size="small">
              <TableBody>
              {rows.map((row, index) => (
                  <TableRow key={index} hover>
                      <TableCell className={classes.tableCell} align="left">{row}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Удалить синоним">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {handleDeleteSynonym(index)}}
                            variant="outlined"
                          >
                            <DeleteIcon/>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
      </DialogContent>
      <DialogActions disableSpacing={true}>
        <Button onClick={handleAccept} color="primary" disabled={rows.length < 2} className={classes.buttonClose}>
        Принять
        </Button>
        <Button onClick={props.handleClose} color="secondary" className={classes.buttonClose}>
        Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
}