import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Поле имя обязательно для заполнения').max(20, 'Имя может содержать максимум 20 символов')
    .min(3, 'Имя должно содержать минимум 3 символа').matches(/^[a-zA-Zа-яА-ЯёЁ0-9_]{3,20}$/, 'Допускаются только буквы, цифры и символ "_"'),
  email: Yup.string().required('Поле email обязательно для заполнения').email('Укажите корректый e-mail'),
  password: Yup.string().required('Поле пароль обязательно для заполнения').max(30, 'Пароль может содержать максимум 30 символов')
  .min(4, 'Пароль должен содержать минимум 4 символа')
  .matches(/^[a-zA-Zа-яА-ЯёЁ0-9_\-+=!@#$%^&*()]{3,30}$/, 'Допускаются только буквы, цифры и символы: _-+=!@#$%^&*()'),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  google: {
    paddingTop: 10,
    display: 'block'
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    height: '40px',
  },
  errorMessage: {
    color: 'red',
    fontSize: '12px',
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    password: '',
  };

  const onSubmit = (values) => {
    setDisabled(true);

    window.grecaptcha.ready(function() {
      window.grecaptcha.execute(process.env.REACT_APP_SITE_KEY, {action: 'sign_up'}).then(function(token) {
        fetch(process.env.REACT_APP_API_URL + '/recapcha', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({token: token})
        })
        .then(r => r.json())
        .then(r => { 
          if ('success' === r.status && r.message.success && r.message.action === 'sign_up' && r.message.score >= 0.5) { 
            fetch(process.env.REACT_APP_API_URL + '/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            })
                .then(r => r.json())
                .then(r => { 
                  setDisabled(false);
                  if ('success' === r.status) { 
                    setMessage('Поздравляем с регистрацией на нашем сайт! Добро пожаловать!');
                    setOpen(true);
                  } else {
                    setOpenAlert(true);  
                  }
                }).catch(err => {
                  setDisabled(false);
                  setOpenAlert(true);
                })
          } else {
            console.error(r.message);
            setDisabled(false);
            setMessage('Возникло подозрение, что Вы робот. Попробуйте зарегистрироваться ещё раз, либо используйте другой браузер.');
            setOpen(true);
          }
        }).catch(err => {
          console.error(err.message);
          setDisabled(false);
          setMessage('Возникло подозрение, что Вы робот. Попробуйте зарегистрироваться ещё раз, либо используйте другой браузер.');
          setOpen(true);
        })
      });
    });
};

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const handleClose = () => {
    setOpen(false);
    history.push("/");
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
           Регистрация
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Имя или псевдоним"
                autoComplete="name"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}/>
                {formik.touched.name && formik.errors.name ? (
                  <Typography className={classes.errorMessage}>{formik.errors.name}</Typography>
                ) : null}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}/>
                {formik.touched.email && formik.errors.email ? (
                  <Typography className={classes.errorMessage}>{formik.errors.email}</Typography>
                ) : null}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Пароль"
                autoComplete="password"
                id="password"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
                {formik.touched.password && formik.errors.password ? (
                  <Typography className={classes.errorMessage}>{formik.errors.password}</Typography>
                ) : null}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                checked
                label="Я хочу получать новости на email."
              />
            </Grid>
          </Grid>
          <Button
            data-badge="inline"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={disabled}
          >
            Зарегистрироваться
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to="/login" component={RouterLink} variant="body2">
                Уже есть аккаунт? Войти
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">
          Регистрация
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus disableFocusRipple={true} onClick={handleClose} color="primary">
            Принять
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openAlert} autoHideDuration={10000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          Регистрация не удалась. Возожно данный email уже был использован для регистрации.
        </Alert>
      </Snackbar>   
      <Typography className={classes.google} variant="caption">   
        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy"> Privacy Policy </a> and <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
      </Typography>
    </Container>
  );
}