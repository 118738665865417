import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react-lite";
import { useMst } from "../../models/Root";
import { useValidateUser } from '../AuthProvider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: 64,
  },
  btnApprove: {
    backgroundColor: 'lightgreen',
    '&:hover': {
      backgroundColor: '#4caf50',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
  },
  btnDelete: {
    backgroundColor: 'lightcoral',
    marginLeft: '8px',
    '&:hover': {
      backgroundColor: '#d06060',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
  },  
}));

const rows = [
  {id: 1, comment: 'Frozen yoghurt'},
  {id: 2, comment: 'Ice cream sandwich'},
  {id: 3, comment: 'Eclair'},
  {id: 4, comment: 'Cupcake'},
  {id: 5, comment: 'Gingerbread'},
];

const Mainbar = observer(() => {
  const classes = useStyles();
  const { book, mobile, tree } = useMst();
  const validUser = useValidateUser('ADMIN AUTHOR MODERATOR');
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <main className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Комментарий</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell scope="row">
                  {row.comment}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex' }}>
                    <Box>
                      <Button  className={classes.btnApprove} variant="contained" size="small">Одобрить</Button>
                      <Button  className={classes.btnDelete} variant="contained" size="small">Удалить</Button>
                    </Box>
                    <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </main>
  );
});

export default Mainbar;