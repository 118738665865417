import {
    types,
    flow,
  } from "mobx-state-tree";
import { authFetch } from '../components/AuthProvider';

export const DicItem = types
.model("DicItem", {
  id: types.identifierNumber,
  text: types.optional(types.string, ""),
})
.actions(self => {
  function updateText(newText) {
    self.text = newText
  }

  return {
    updateText
  }
})

export const Dic = types
.model("Dic", {
  state: types.enumeration("State", ["init", "pending", "done", "error"]),
  name: types.string,
  items: types.array(DicItem),
})
.actions(self => {
  function addDicItem(dicItem) {
    self.items.push(dicItem);
  }

  function updateItems(json) {
    self.items.clear()

    json.forEach(itemJson => {
      self.items.push(itemJson)
    })
  }

  const loadItems = flow(function* loadItems() {
    if (self.state !== "pending") {  
      self.state = "pending"
      try {
        const json = yield authFetch(
                          process.env.REACT_APP_API_URL + `/dics/` + self.name, {
                          method: 'GET',
                          headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                          }
                      }
                    ).then(response => response.json()) 
          updateItems(json.message)
          self.state = "done"
      } catch (err) {
          console.error("Failed to load dictionary ", err)
          self.state = "error"
      }
    }
  })

  return {
      loadItems,
      addDicItem,
  }
})

export const Dics = types
.model("Dics", {
  items: types.array(Dic),
})
.actions(self => {
  function getDic(name, forceLoad = false) {
    let dic = null
    self.items.forEach(item => {
      if (item.name === name) {
        dic = item
        if (forceLoad) {
          dic.loadItems()
        }
      }
    })

    if (dic === null) {
      dic = Dic.create({name, state: "init", items: []})
      dic.loadItems()
      self.items.push(dic)
    }

    return dic.items
  }

  return {
      getDic,
  }
})