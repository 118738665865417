import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { observer } from "mobx-react-lite";
import { useMst } from "../models/Root";
import { isValidReference } from "mobx-state-tree";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import { useAuth, getUserId } from './AuthProvider';
import CommentTree from './CommentTree';

const useStyles = makeStyles((theme) => ({
  cardComment: {
    minWidth: 240,
    marginTop: 8,
  },
  cardNoComment: {
    paddingTop: 20,
  },
  commentHeder: {
    marginBlockStart: 0,
    marginBlockEnd: '0.5em',
    fontWeight: 500,
  },
  commentSubheder: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'gray',
  },
  commentTextArea: {
    width: '100%',
    marginBlockStart: '1rem',
    '& textarea': {
      fontSize: theme.typography.body2.fontSize,
    }
  },
  commentSendButton: {
    marginBlockStart: '0.5rem',  
  },
  topPagination: {
    marginBlockStart: '1rem',
    marginBlockEnd: '1rem',
  }
}));

const Comments = observer(() => {
  const classes = useStyles();
  const { book } = useMst();
  const [value, setValue] = useState('');
  const [logged] = useAuth();

  const handleChangeText = (event) => {
    setValue(event.target.value.slice(0, 10000));
  };

  const handleChangePage = (event, value) => {
    if (value !== book.selectedStory.comment_current_page) {
      book.loadComments(book.selectedStory.id, value)
    }
  };

  const handleSendClick = () => {
    let comment = {
      id_user: getUserId(),
      text: value,
    }

    book.selectedStoryAddComment(comment);
    
    setValue('');
  };

  useEffect(() => {
    setValue('');
  }, [book.commentAnswering]);

  return (
    <Card className={classes.cardComment} variant="outlined">
        <CardContent>
            <Typography className={classes.commentHeder} variant="body2" component="p">
            Комментарии: {isValidReference(() => book.selectedStory) ? book.selectedStory.comment_count : 0}
            {/* {logged && <Typography className={classes.commentSubheder}>Внимание! Ваш комментарий появится после проверки.</Typography>} */}
            </Typography>
            <Divider />
            {!logged ?
              <Alert variant="outlined" severity="info">
                Только зарегистрированные пользователи могут оставлять комментарии. 
                <Link component={RouterLink} to="/login"> Войдите</Link>, пожалуйста.
              </Alert>
              :
              book.commentAnswering < 1 ?
                <div>
                  <TextField className={classes.commentTextArea}
                      id="new-comment-textarea"
                      label={"Написать комментарий (" + (10000 - value.length).toString() + ')'}
                      placeholder="Написать комментарий..."
                      multiline
                      rowsMax={5}
                      variant="outlined"
                      value={value}
                      onChange={handleChangeText}
                  />
                  <Button className={classes.commentSendButton} onClick={handleSendClick} variant="outlined" color="primary" size="small" disabled={value.length === 0}>
                      Отправить
                  </Button>
                </div>
                : ""
            }
            {isValidReference(() => book.selectedStory) && book.selectedStory.comment_count > 0 ? 
              <div>
                {book.selectedStory.comment_pages > 1 ?
                  <Pagination className={classes.topPagination} count={book.selectedStory.comment_pages} page={book.selectedStory.comment_current_page} onChange={handleChangePage} shape="rounded" color="primary" variant="outlined" size="small" showFirstButton showLastButton/>
                : "" 
                }
                <CommentTree />
                {book.selectedStory.comment_pages > 1 ?
                <Pagination className={classes.bottonPagination} count={book.selectedStory.comment_pages} page={book.selectedStory.comment_current_page} onChange={handleChangePage} shape="rounded" color="primary" variant="outlined" size="small" showFirstButton showLastButton/>
                : "" 
                }
              </div>
              :
              <Typography className={classes.cardNoComment} variant="body2" component="p">
                Комментариев пока нет...
              </Typography>
            }
        </CardContent>
    </Card>
  );
});

export default Comments;