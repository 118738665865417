import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SelectIcon from '@material-ui/icons/MoreHoriz';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import SelectStory from './SelectStory';
import { walk } from 'react-sortable-tree';
import { observer } from "mobx-react-lite";

function isObjectEmpty(value) {
  return (
      Object.prototype.toString.call(value) === '[object Object]' && JSON.stringify(value) === '{}'
  );
}

function getNodeKey({ node }) {
  return node.id;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    minWidth: 300,
    maxWidth: 550,
    maxHeight: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexWrap: 'wrap',
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  cardContent: {
    padding: '10px',
    '&:last-child': {
      paddingBottom: '10px',
    }
  },
  linkHeder: {
    marginBlockStart: 0,
    marginBlockEnd: '0.5em',
  },
  button: {
    marginBlockStart: '0.5rem',
    marginRight: '0.5rem',
  },
  textField: {
    margin: theme.spacing(1),
    width: '510px',
  },
  storyField: {
    margin: theme.spacing(1),
    width: '460px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '510px',
  },
  radioGroupControl: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '-5px',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  footer: {
    textAlign: 'right',
  }
}));

const LinkProps = observer((props) => {
  const classes = useStyles();
  const [target, setTarget] = useState('_blanck');
  const [type, setType] = useState('url');
  const [url, setUrl] = useState('');
  const [text, setText] = useState('');
  const [storyId, setStoryId] = useState(0);
  const [valid, setValid] = useState(false);
  const [openSelectStory, setOpenSelectStory] = useState(false);

  const handleCloseSelectStory = (selected) => {
    setOpenSelectStory(false);

    if (selected > 0) {
      setStoryId(selected);
      walk({treeData: props.treeData, getNodeKey, callback: (nodeInfo) => {
        if (String(nodeInfo.node.id) == selected) {
          setUrl(nodeInfo.node.name);
        }
      }, ignoreCollapsed: false});
    }
  }

  const handleCancel = () => {
    props.onClose(null);
  };

  const handleAccept = () => {
    let validUrl;

    if (type == 'url') {
      validUrl = url.trim();
      if (!validUrl.startsWith('http')) {
        validUrl = 'https://' + validUrl;
      }
    } else {
      validUrl = storyId;
    }

    let link = { 
      type,
      url: validUrl,
      text,
      target
    };
    props.onClose(link);
  };

  const handleChangeTarget = (event) => {
    setTarget(event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
    setUrl('');
    setStoryId(0);
  };

  const handleChangeUrl = (event) => {
    setUrl(event.target.value);
  };

  const handleChangeText = (event) => {
    setText(event.target.value);
  };

  const validateForm = () => {   
    if ((url.trim().length > 0) && (text.trim().length > 0)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  useEffect(() => {
    if (!isObjectEmpty(props.link)) {
      setText(props.link.text);
      if (props.link.type == 'url') {
        setUrl(props.link.url);
      } else {
        setUrl('');
        walk({treeData: props.treeData, getNodeKey, callback: (nodeInfo) => {
          if (String(nodeInfo.node.id) == props.link.url) {
            setUrl(nodeInfo.node.name);
            setStoryId(nodeInfo.node.id);
          }
        }, ignoreCollapsed: false});
      }
      setType(props.link.type);
      setTarget(props.link.target);
    }
  }, [props.link]);

  useEffect(() => {
    validateForm();
  }, [url, text]);

  return (
    <Modal
      open={props.open}
      onClose={handleCancel}
      aria-labelledby="dic-modal-title"
      aria-describedby="dic-modal-list"
    >
      <Card className={classes.paper} variant="outlined">
        <CardContent className={classes.cardContent}>
          <h4 id="dic-modal-title" className={classes.linkHeder}>
            Добавление\редактирование ссылки
          </h4>
          <Divider />
          <form className={classes.root} noValidate autoComplete="off">
          <FormControl component="fieldset" className={classes.radioGroupControl}>
            <FormLabel component="legend">Тип ссылки</FormLabel>
            <RadioGroup aria-label="type" name="type" value={type} className={classes.radioGroup} onChange={handleChangeType}>
              <FormControlLabel value="url" control={<Radio />} label="Страница в интернете" />
              <FormControlLabel value="story" control={<Radio />} label="История" />
            </RadioGroup>
          </FormControl>
            {type==='url' ?
              <TextField id="standard-basic" className={classes.textField} label="URL" value={url} onChange={handleChangeUrl} fullWidth/>
            :
              <div>
                <TextField id="standard-basic" className={classes.storyField} label="История" value={url} onChange={handleChangeUrl} InputProps={{readOnly: true}}/>
                <IconButton aria-label="select" onClick={() => setOpenSelectStory(true)}>
                  <SelectIcon />
                </IconButton>
                <SelectStory open={openSelectStory} onClose={handleCloseSelectStory} storyId={storyId} treeData={props.treeData}/>
              </div>
            }
            <TextField id="filled-basic" className={classes.textField} label="Отображаемый текст" value={text} onChange={handleChangeText} fullWidth/>
            <FormControl className={classes.formControl}>
              <InputLabel id="select-target-label">Открывать ссылку в...</InputLabel>
              <Select
                labelId="select-target-label"
                id="select-target"
                value={target}
                onChange={handleChangeTarget}
              >
                <MenuItem value={'_blanck'}>новом окне</MenuItem>
                <MenuItem value={'_self'}>том же окне</MenuItem>
              </Select>
            </FormControl>
          </form>
          <div className={classes.footer}>
            <Button className={classes.button} onClick={handleAccept} disabled={!valid} variant="outlined" color="primary" size="small">
                Принять
            </Button>
            <Button className={classes.button} onClick={handleCancel} variant="outlined" color="primary" size="small">
                Отмена
            </Button>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
});

export default LinkProps;